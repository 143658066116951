import React from 'react';
import ChapterRegisterPresenter from './ChapterRegisterPresenter';

const ChapterRegisterContainer = (props) => {
  /* ===== PROPS ===== */
  const {
    type,
    chapters,
    setChapters,
    sections,
    setSections,
    onDataList,
    onSubmit,
    inputData,
    htmlString,
    questionnaire,
    screenSize,
  } = props;
  const NewChapters = chapters
    ?.map((item, index) => ({
      id: index + 1,
      ...item,
    }))
    ?.sort((a, b) => a.order - b.order);

  const NewSections = sections
    ?.map((item, index) => ({
      id: index + 1,
      ...item,
    }))
    ?.sort((a, b) => a?.order - b?.order);

  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <ChapterRegisterPresenter
      type={type}
      chapters={NewChapters}
      setChapters={setChapters}
      sections={NewSections}
      setSections={setSections}
      onDataList={onDataList}
      onSubmit={onSubmit}
      inputData={inputData}
      htmlString={htmlString}
      questionnaire={questionnaire}
      screenSize={screenSize}
    />
  );
};

ChapterRegisterContainer.defaultProps = {};

export default ChapterRegisterContainer;
