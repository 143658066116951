import React from 'react';
import MyPostsPresenter from './MyPostsPresenter';
import { getCookie } from 'utils';
import { CategoryApi, FileApi, LectureApi, ReviewApi, UserApi } from 'api';

const MyPostsContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureFiles = FileApi.GetLectureFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const reviews = ReviewApi.GetReviews(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const idx = parseInt(getCookie('USER'));

  // console.log('reviews', reviews && reviews);
  // console.log('lectures', lectures && lectures);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <MyPostsPresenter
      lectures={lectures}
      lectureFiles={lectureFiles}
      users={users}
      reviews={reviews}
      categories={categories}
      idx={idx}
    />
  );
};

MyPostsContainer.defaultProps = {};

export default MyPostsContainer;
