import React, { useState, useEffect } from 'react';
import MyInfoPresenter from './MyInfoPresenter';
import { AgencyApi, FileApi, LectureApi, UserApi } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { getCookie } from 'utils';

const MyInfoContainer = (props) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const uId = getCookie('USER');
  const loginWho = useRecoilValue(loginUser);
  const [newLectures, setNewLectures] = useState([]);
  const recommend = LectureApi.GetRecommendLectures(null, {
    cacheTime: 0,
  })?.data;
  const lectureFiles = FileApi.GetLectureFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const { data: userData } =
    loginWho === 'agency'
      ? AgencyApi.GetAgency(parseInt(uId), null, {
          enabled: !!loginWho,
        })
      : UserApi.GetUser(parseInt(uId), null, {
          enabled: !!loginWho,
        });
  const teacherFiles = FileApi.GetTeacherFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (recommend?.status === 200 && lectureFiles) {
      const l = recommend?.data?.map((item) => ({
        ...item,
        thumbnail: lectureFiles?.filter(
          (subitem) =>
            item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
        )[0]?.file_url,
      }));

      setNewLectures(l);
    }
  }, [recommend, lectureFiles]);

  const data = React.useMemo(() => {
    return {
      ...userData?.data,
      ...(loginWho === 'agency'
        ? {}
        : {
            teacherFiles: teacherFiles?.filter(
              (file) => file?.user_id === parseInt(uId)
            ),
          }),
    };
  }, [userData, loginWho, teacherFiles, uId]);

  /* ===== RENDER ===== */
  return (
    <MyInfoPresenter loginWho={loginWho} data={data} lectures={newLectures} />
  );
};

MyInfoContainer.defaultProps = {};

export default MyInfoContainer;
