import React, { useState, useEffect } from 'react';
import EventListPresenter from './EventListPresenter';
import { EventApi, EventOfUserApi } from 'api';
import { getCookie } from 'utils';

const EventListContainer = ({ screenSize }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const uid = getCookie('USER');
  const [newData, setNewData] = useState([]);

  /* ===== Data ===== */
  const events = EventApi.GetEvents(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const eventOfUsers = EventOfUserApi.GetEventOfUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  // console.log('Data', Data);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  const newEvents = React.useMemo(() => {
    return events?.map((item) => ({
      ...item,
      users: eventOfUsers?.filter(
        (subitem) => item?.event_id === subitem?.event_id
      ),
    }));
  }, [eventOfUsers, events]);

  useEffect(() => {
    if (newEvents?.length) {
      const newEventList = newEvents?.filter((item) =>
        item?.users?.some((user) => user?.user_id === parseInt(uid))
      );

      setNewData(newEventList);
    }
  }, [newEvents, uid]);

  /* ===== RENDER ===== */
  return <EventListPresenter events={newData} screenSize={screenSize} />;
};

EventListContainer.defaultProps = {};

export default EventListContainer;
