/**
 *
 *
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import { Input, Divider, Radio, Button as Btn, Form } from 'antd';
import {
  Content,
  Row,
  Col,
  Title,
  Button,
  ModalLayout,
  LoadingPage,
} from 'components';
import MessageAlert from 'utils/MessageAlert';
import { deleteCookie, getCookie, setCookie, removeCookie } from 'utils';
import { UserApi, AgencyApi, EmailApi } from 'api';
import { NiceApi } from 'api';
import validateCheck from 'utils/Validation';
import { useLocation } from 'react-router-dom';

// const { intl } = globalObj;

/**
 * [Component] Login
 * --
 */
function Login(props) {
  /* ===== Props ===== */
  const { navigation, isLogin, setIsLogin, screenSize, queryClient } = props;
  const location = useLocation();

  // const dispatch = useDispatch();
  /* ===== Mutate ===== */
  //FIXME - 01/26일 수정완료
  const updateUserPassword = UserApi.UpdateUserPassword({
    onSuccess: (data) => {
      const { status } = data;
      if (status === 200) {
        alert('정상적으로 수정되었습니다');
        window.location.reload();
      } else {
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    },
  });
  //FIXME - 01/26일 수정완료
  const updateAgencyPassword = AgencyApi.UpdateAgencyPassword({
    onSuccess: (data) => {
      const { status } = data;
      if (status === 200) {
        alert('정상적으로 수정되었습니다');
        window.location.reload();
      } else {
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    },
  });

  /* ===== Styles ===== */
  const styles = {
    modalLabel: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.02em',
      color: '#000000',
    },
    sectionButton: {
      width: '30px',
      height: '30px',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      fontSize: '18px',
    },
  };

  /* ===== State ===== */
  const [isOpened, setIsOpened] = useState(false);
  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  // 로그인 id, pw 입력 값
  const [account, setAccount] = useState({
    id: '',
    pw: '',
  });
  // 로그인 radio 버튼
  const [radio, setRadio] = useState(1);
  // ID/PW 찾기
  const [foundRadio, setFoundRadio] = useState(1);
  // const [authNum, setAuthNum] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [visiblePhoneCheck, setVisiblePhoneCheck] = useState(false);
  const [visibleIDCheck, setVisibleIDCheck] = useState(false);
  const [foundAccount, setFoundAccount] = useState([]);
  const [userAccount, setUserAccount] = useState('');

  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const agencies = AgencyApi.GetAgencies(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  // 본인인증 상태 값
  const [encodeData, setEncodeData] = useState(null);
  const [isPopup, setIsPopup] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [authInfo, setAuthInfo] = useState({});
  const [authType, setAuthType] = useState(1);
  // 기관 이메일 인증
  const [email, setEmail] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [inputAuth, setInputAuth] = useState('');
  const [timeText, setTimeText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // NOTE: 성공 : Y, 실패 : R, 미실행 : N
  const [authCheck, setAuthCheck] = useState('N');

  /* ===== Hooks ===== */

  /* ===== Functions ===== */
  /**
   * Radio 버튼 상태 변경 함수
   * --
   * @param {*} e
   */
  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...account };

    if (validateCheck('isBlank', v)) {
      MessageAlert.warning('공백은 입력하실 수 없습니다!');
      newData[t] = v.replace(/\s/g, '');
    } else {
      newData[t] = v;
    }

    if (t === 'pw') {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      if (reg.test(v)) {
        MessageAlert.warning('한글은 입력하실 수 없습니다!');
        newData[t] = v.replace(reg, '');
      } else {
        newData[t] = v;
      }
    }

    const d = {
      ...newData,
    };
    setAccount(d);

    if (callback) callback(v);
  };

  /**
   * 로그인 함수
   * --
   */
  const handleLogin = async () => {
    const deniedToken = getCookie('BRAINHEAL_ACCESS_DENIED_TOKEN');

    if (deniedToken)
      return MessageAlert.warning('5분간 로그인하실 수 없습니다!');

    const { id, pw } = account;

    if (!id || !pw) {
      MessageAlert.warning('계정정보를 모두 입력해주세요');
      return;
    }

    if (validateCheck('isBlank', id) || validateCheck('isBlank', pw)) {
      MessageAlert.warning('공백이 포함되어있습니다.');
      return;
    }

    const koreanReg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (koreanReg.test(id) || koreanReg.test(pw)) {
      MessageAlert.warning('한글이 포함되어있습니다.');
      return;
    }

    try {
      let res;
      const fail = getCookie('loginFail');
      const data = JSON.parse(fail);
      const today = new Date();

      switch (radio) {
        case 1:
          res = await UserApi.LoginUser({
            user_account: id,
            password: pw,
          });
          break;
        case 2:
          res = await AgencyApi.LoginAgency({
            agency_account: id,
            password: pw,
          });
          break;
        default:
          break;
      }

      if (res === 200) {
        setIsLogin(true);
        const prev = location.state?.prev;
        if (prev) {
          navigation(prev, { replace: true });
        } else {
          navigation('/', { replace: true });
        }

        deleteCookie('loginFail', {});
        MessageAlert.success('로그인 성공');
        queryClient.invalidateQueries();
      } else {
        if (fail) {
          if (data?.count === 5) {
            const time = 5 * 60 * 1000;
            deleteCookie('loginFail', {});
            removeCookie('BRAINHEAL_ACCESS_DENIED_TOKEN', data, time);
          } else {
            setCookie(
              'loginFail',
              JSON.stringify({ count: data?.count + 1, date: today })
            );
          }
        } else {
          setCookie('loginFail', JSON.stringify({ count: 1, date: today }));
        }

        window.alert(
          '계정정보를 다시 확인해주세요.\n5회 실패 시 5분간 로그인 하실 수 없습니다.'
        );
      }
    } catch (e) {
      console.log('[handleLogin] Error: ', e);
      MessageAlert.error('로그인 실패');
    }
  };

  /**
   * 로그인 버튼 Enter 처리
   * --
   * @param {*} e
   * @param {*} data
   */
  const handleEnterSubmit = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleCheckID = () => {
    const id =
      foundRadio === 1
        ? users.filter((item) => item.phone === authInfo.phone)[0]?.user_account
        : agencies.filter(
            (item) =>
              item.email === authInfo.email &&
              item.agency_account === userAccount
          )[0]?.agency_account;

    if (id) {
      setVisibleIDCheck(true);
    } else {
      MessageAlert.warning('아이디 정보가 다릅니다.');
    }
  };

  /**
   * 비밀번호 수정
   * --
   */
  const handleUpdatePassword = () => {
    if (newPassword?.length < 8 || newPassword?.length > 16) {
      MessageAlert.warning('비밀번호는 8자리이상 16자리 이하로 입력해주세요.');
      return;
    } else if (newPassword === newPasswordCheck) {
      const findMatchInfo =
        foundRadio === 1
          ? users.filter((item) => item.phone === authInfo.phone)[0]
          : agencies.filter(
              (item) =>
                item.email === authInfo.email &&
                item.agency_account === userAccount
            )[0];
      if (foundRadio === 1) {
        try {
          //FIXME - 01/26일 수정완료
          updateUserPassword.mutate({
            user_id: findMatchInfo?.user_id,
            user_account: findMatchInfo?.user_account,
            email: findMatchInfo?.email,
            password: newPassword,
          });
        } catch (e) {
          throw e;
        }
      } else if (foundRadio === 2) {
        try {
          //FIXME - 01/26일 수정완료
          updateAgencyPassword.mutate({
            agency_id: findMatchInfo?.agency_id,
            agency_account: findMatchInfo?.agency_account,
            email: findMatchInfo?.email,
            password: newPassword,
          });
        } catch (e) {
          throw e;
        }
      }
    } else {
      MessageAlert.warning('항목을 정확히 입력해 주세요!');
      return;
    }
  };

  /**
   * 아이디 / 비밀번호 찾기 함수
   * --
   * @type 1: ID, 2:PW
   */
  const handleFoundIDPW = (type, info) => {
    if (type === 1) {
      if (foundRadio === 1) {
        const id = users.filter((item) => item.phone === info.phone)[0]
          ?.user_account;

        if (id) {
          setFoundAccount([id?.slice(0, id.length - 2) + '**']);
          setVisiblePhoneCheck(false);
        } else {
          setFoundAccount(['해당 번호로 가입된 아이디가 없습니다']);
          setVisiblePhoneCheck(false);
        }
      } else if (foundRadio === 2) {
        const id = agencies
          .filter((item) => item?.email === email)
          ?.map((item) => item?.agency_account);
        if (id?.length) {
          const idList = id?.map(
            (item) => item?.slice(0, item.length - 2) + '**'
          );
          setFoundAccount(idList);
          setVisiblePhoneCheck(false);
        } else {
          setVisiblePhoneCheck(false);
          setFoundAccount(['해당 이메일로 가입된 아이디가 없습니다']);
        }
        setIsAuth(true);
      }
    } else if (type === 2) {
      setIsOpened(false);
      setPasswordModalOpened(true);
    }
  };

  /**
   * 아이디찾기 모달 오픈
   * --
   */
  const handleFonud = (open) => {
    setIsOpened(!open);
  };

  /**
   * 인증코드 입력 시간 제한 설정
   * --
   */
  const timerStart = useRef(null);
  const handleStartTimer = () => {
    if (timerStart.current) {
      clearInterval(timerStart.current);
      timerStart.current = null;
    }

    let time = 300;
    timerStart.current = setInterval(() => {
      --time;

      if (time === 0) {
        setAuthCode('만료');
        clearInterval(timerStart.current);
        timerStart.current = null;
        setTimeText('시간만료');
        return;
      }

      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      setTimeText(`${formattedMinutes}:${formattedSeconds}`);
    }, 1000);
  };

  /**
   * 인증 이메일 발송
   * --
   */
  const handleSendAuthcode = async () => {
    if (!email || email === '')
      return MessageAlert.warning('이메일을 입력해주세요!');
    if (!validateCheck('email', email))
      return MessageAlert.warning('이메일 형식이 아닙니다!');

    try {
      setIsLoading(true);
      const { status, data } = await EmailApi.Sendmail({
        toEmail: email,
        type: 'code',
      });

      if (status !== 200)
        return MessageAlert.error('인증 메일 발송에 실패하였습니다');

      MessageAlert.success('입력하신 메일로 인증코드가 발송되었습니다');
      const code = data?.code;
      setAuthCode(code);

      handleStartTimer();
    } catch (err) {
      console.log('이메일 인증 에러!', err);
      MessageAlert.error(
        '이메일 인증에 실패하였습니다.\n관리자에게 문의해주세요!'
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 인증번호 확인
   * --
   */
  const handleAuthcodeCheck = () => {
    if (authCode === '만료') {
      setAuthCheck('N');
      return MessageAlert.warning(
        '인증코드가 만료되었습니다. 다시 인증해주세요!'
      );
    }

    if (parseInt(authCode) !== parseInt(inputAuth)) {
      setAuthCheck('R');
      return MessageAlert.warning('인증코드를 확인해주세요!');
    }

    MessageAlert.success('인증되었습니다!');
    clearInterval(timerStart);
    setAuthCheck('Y');
    setAuthInfo({
      email: email,
    });
  };

  /**
   * 본인인증 모달
   * --
   */
  const handleAuthModal = async (type) => {
    if (foundRadio === 1) {
      setIsPopup(true);
      const encodeDataToken = await NiceApi.getNiceToken();
      window.open(
        '',
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );

      document.form_chk.action =
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
      document.form_chk.EncodeData.value = encodeDataToken;
      document.form_chk.target = 'popupChk';
      document.form_chk.submit();
      setAuthType(type);
    } else {
      if (authCheck !== 'Y')
        return MessageAlert.warning('이메일 인증 후 진행해주세요!');

      handleFoundIDPW(type);
    }
  };

  /* ===== Hook ===== */
  /**
   * 쿠키가 저장되어 있는지 체크
   * --
   */
  useEffect(() => {
    const isCookie = getCookie('BRAINHEAL_ACCESS_TOKEN');

    if (isCookie) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isLogin, setIsLogin]);

  /**
   * 인증번호 발급 이후 모달창 나갔을 시 초기화
   * --
   */
  useEffect(() => {
    if (
      isOpened !== true &&
      passwordModalOpened !== true &&
      visiblePhoneCheck === true
    ) {
      setIsAuth(false);
    }
  }, [isOpened, passwordModalOpened, visiblePhoneCheck]);

  /**
   * 휴대폰 본인인증
   * --
   */
  useEffect(() => {
    const getDecodeData = async () => {
      const result = await NiceApi.verifyToken(
        `?EncodeData=${encodeURIComponent(encodeData)}`
      );

      // 본인인증 받은 생일 값 정제
      const year = result.birthdate.slice(0, 4);
      const month = result.birthdate.slice(4, 6);
      const day = result.birthdate.slice(6, 8);

      // 본인인증 받은 휴대폰 값 정제
      const phoneNum01 = result.mobileno.slice(0, 3);
      const phoneNum02 = result.mobileno.slice(3, 7);
      const phoneNum03 = result.mobileno.slice(7, 11);

      setAuthInfo({
        ...result,
        birthday: `${year}-${month}-${day}`,
        phone: `${phoneNum01}-${phoneNum02}-${phoneNum03}`,
      });

      handleFoundIDPW(authType, {
        ...result,
        birthday: `${year}-${month}-${day}`,
        phone: `${phoneNum01}-${phoneNum02}-${phoneNum03}`,
      });
    };

    if (isPopup) {
      if (encodeData && encodeData !== '') {
        setIsPopup(false);
        setIsAuth(true);

        getDecodeData();
      }
    }
    // eslint-disable-next-line
  }, [isPopup, encodeData]);

  /**
   * 본인인증 창 닫힘 감지
   * --
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'close') {
        const encodeNiceData = getCookie('encodeData');
        setEncodeData(encodeNiceData);
      }
    };

    if (isPopup) {
      window.addEventListener('message', handleMessage);
    } else {
      window.removeEventListener('message', handleMessage);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isPopup]);

  /**
   * 인증여부 체크
   * --
   */
  useEffect(() => {
    if (!isAuth) {
      deleteCookie('encodeData', {});
      setAuthInfo({});
      setIsPopup(false);
    }
  }, [isAuth]);

  /**
   * 이메일 인증 후 변경 방지
   * --
   */
  useEffect(() => {
    setAuthCheck('N');
  }, [email]);

  /* ===== Render ===== */
  return (
    <>
      <Content
        maxWidth={'100%'}
        padding={'48px 0 84px'}
        backgroundColor={'#FAFAFA'}
      >
        <Content maxWidth={792} padding={0} backgroundColor={'#FAFAFA'}>
          <Row>
            <Col x={24}>
              <Content maxWidth={'100%'} backgroundColor={'#FAFAFA'}>
                <Row>
                  <Col
                    x={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '48px',
                    }}
                  >
                    <Radio.Group
                      onChange={handleRadioChange}
                      value={radio}
                      defaultValue={1}
                      size="large"
                    >
                      <Radio
                        value={1}
                        style={{
                          fontSize: '20px',
                          fontWeight: 600,
                          lineHeight: '24px',
                        }}
                      >
                        고객
                      </Radio>
                      <Radio
                        value={2}
                        style={{
                          fontSize: '20px',
                          fontWeight: 600,
                          lineHeight: '24px',
                        }}
                      >
                        기관
                      </Radio>
                    </Radio.Group>
                  </Col>
                  <Col x={24}>
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{
                        border: '1px solid #9E9E9E',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '50px 30px',
                      }}
                    >
                      <Form>
                        <Content maxWidth={'384px'} padding={0}>
                          <Row>
                            <Col x={24}>
                              <Title
                                size={2}
                                align="center"
                                style={{
                                  fontSize: '35px',
                                  fontWeight: 700,
                                  lineHeight: '42px',
                                  letterSpacing: '0.02em',
                                  color: '#000000',
                                  marginBottom: '63px',
                                }}
                              >
                                로그인
                              </Title>
                            </Col>
                            <Col x={24}>
                              <label
                                style={{
                                  fontSize: '18px',
                                  fontWeight: 600,
                                  lineHeight: '22px',
                                  letterSpacing: '0.02em',
                                }}
                              >
                                아이디
                                <Input
                                  placeholder="아이디를 입력해주세요"
                                  size="large"
                                  style={{
                                    height: '51px',
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    marginTop: '8px',
                                  }}
                                  name="id"
                                  value={account.id}
                                  onChange={(e) =>
                                    handleChange('id', e.target.value)
                                  }
                                  onKeyDown={(e) => handleEnterSubmit(e)}
                                  autoComplete="username"
                                />
                              </label>
                            </Col>
                            <Col x={24} style={{ marginTop: '20px' }}>
                              <label
                                style={{
                                  fontSize: '18px',
                                  fontWeight: 600,
                                  lineHeight: '22px',
                                  letterSpacing: '0.02em',
                                }}
                              >
                                비밀번호
                                <Input.Password
                                  placeholder="비밀번호를 입력해주세요"
                                  size="large"
                                  style={{
                                    height: '51px',
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    marginTop: '8px',
                                  }}
                                  name="pw"
                                  value={account.pw}
                                  onChange={(e) =>
                                    handleChange('pw', e.target.value)
                                  }
                                  onKeyDown={(e) => handleEnterSubmit(e)}
                                  autoComplete="current-password"
                                />
                              </label>
                            </Col>
                            <Col x={24} style={{ marginTop: '40px' }}>
                              <Button
                                onClick={handleLogin}
                                style={{
                                  width: '100%',
                                  height: '56px',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '22px',
                                }}
                              >
                                로그인
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col x={24} style={{ marginTop: '20px' }}>
                              <Btn
                                onClick={() => handleFonud()}
                                style={{
                                  padding: '15px 0',
                                  margin: '0 auto',
                                  color: '#333',
                                  border: 'none',
                                  background: 'none',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  boxShadow: 'none',
                                }}
                              >
                                계정 또는 패스워드를 찾으시나요?{' '}
                                <span
                                  style={{
                                    color: '#1890ff',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                    marginLeft: 5,
                                  }}
                                >
                                  ID/PW 찾기
                                </span>
                              </Btn>
                            </Col>
                            <Col x={24}>
                              <Divider>OR</Divider>
                            </Col>
                            <Col x={24}>
                              <Button
                                color="default"
                                type="secondary"
                                onClick={() => navigation('/join/1')}
                                style={{
                                  width: '100%',
                                  height: '56px',
                                  background: 'none',
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  lineHeight: '22px',
                                }}
                              >
                                회원가입
                              </Button>
                            </Col>
                          </Row>
                        </Content>
                      </Form>
                    </Content>
                  </Col>
                </Row>
              </Content>
            </Col>
          </Row>
        </Content>
      </Content>

      {/* 비밀번호 찾기 모달 */}
      <ModalLayout
        title={'비밀번호 찾기'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={passwordModalOpened}
        onCancel={setPasswordModalOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 556 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px' }}
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Form>
                <Row
                  style={{
                    width: '100%',
                    // display: foundAccount === '' ? 'flex' : 'none',
                    alignItems: 'center',
                  }}
                >
                  <Col x={6}>
                    <label
                      htmlFor="user_account"
                      style={{ ...styles.modalLabel }}
                    >
                      아이디
                    </label>
                  </Col>
                  <Col x={18}>
                    <Input
                      size="large"
                      placeholder="가입하신 아이디를 입력해주세요."
                      id={'userAccount'}
                      value={userAccount}
                      disabled={visibleIDCheck}
                      onChange={(e) => setUserAccount(e.target.value)}
                      autoComplete="username"
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    display: visibleIDCheck ? 'flex' : 'none',
                    alignItems: 'center',
                    marginTop: '25px',
                  }}
                >
                  <Col x={6}>
                    <label
                      htmlFor="newPassword"
                      style={{ ...styles.modalLabel }}
                    >
                      새 비밀번호
                    </label>
                  </Col>
                  <Col x={18}>
                    <Input.Password
                      size="large"
                      id={'newPassword'}
                      placeholder={'새 비밀번호를 입력하세요.'}
                      value={newPassword}
                      maxLength={16}
                      onChange={(e) => setNewPassword(e.target.value)}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    display: visibleIDCheck ? 'flex' : 'none',
                    alignItems: 'center',
                    marginTop: '25px',
                  }}
                >
                  <Col x={6}>
                    <label
                      htmlFor="newPasswordCheck"
                      style={{ ...styles.modalLabel }}
                    >
                      비밀번호 확인
                    </label>
                  </Col>
                  <Col x={18}>
                    <Input.Password
                      size="large"
                      id={'newPasswordCheck'}
                      placeholder={'새 비밀번호를 다시 입력하세요.'}
                      value={newPasswordCheck}
                      maxLength={16}
                      onChange={(e) => setNewPasswordCheck(e.target.value)}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col x={24}>
              <Button
                style={{
                  width: '100%',
                  height: '56px',
                  marginTop: '24px',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  display: visibleIDCheck ? 'none' : '',
                }}
                onClick={() => handleCheckID()}
              >
                아이디 확인
              </Button>
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                  marginTop: '24px',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  display: !visibleIDCheck ? 'none' : '',
                }}
                onClick={() => handleUpdatePassword()}
              >
                수정 완료
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 가입정보 확인 모달 */}
      <ModalLayout
        title={'가입 정보 확인'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 556 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px' }}
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          {isLoading ? <LoadingPage text={'잠시만 기다려주세요.'} /> : null}
          <Row>
            <Col x={24}>
              <Row
                style={{
                  width: '100%',
                  display: !foundAccount?.length ? 'flex' : 'none',
                  alignItems: 'center',
                }}
              >
                <Col
                  x={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '48px',
                  }}
                >
                  <Radio.Group
                    onChange={(e) => setFoundRadio(e.target.value)}
                    value={foundRadio}
                    defaultValue={1}
                    disabled={visiblePhoneCheck}
                  >
                    <Radio
                      value={1}
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '14px',
                      }}
                    >
                      고객
                    </Radio>
                    <Radio
                      value={2}
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '14px',
                      }}
                    >
                      기관
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              {isAuth ? (
                <Row
                  style={{
                    width: '100%',
                    display: !foundAccount?.length ? 'none' : 'flex',
                    alignItems: 'center',
                    marginTop: '25px',
                  }}
                >
                  <Col x={6}>
                    <label htmlFor="authNum" style={{ ...styles.modalLabel }}>
                      아이디 :
                    </label>
                  </Col>
                  <Col x={18}>
                    {foundAccount?.map((item) => (
                      <p style={{ fontSize: '16px', color: 'rgb(99 46 66)' }}>
                        {item}
                      </p>
                    ))}
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col x={24}>
              {isAuth ? null : (
                <>
                  {foundRadio === 2 ? (
                    <div>
                      <Title
                        style={{
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '22px',
                          letterSpacing: '0.02em',
                          marginBottom: 10,
                        }}
                      >
                        이메일
                      </Title>
                      <div style={{ display: 'flex' }}>
                        <Input
                          size="large"
                          placeholder="이메일을 입력해주세요"
                          style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            fontWeight: 600,
                            height: 50,
                          }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Btn
                          size="large"
                          style={{ height: 50, width: 120, marginLeft: 30 }}
                          onClick={() => handleSendAuthcode()}
                        >
                          {authCode ? '재전송' : '인증메일전송'}
                        </Btn>
                      </div>
                      {authCode ? (
                        <div style={{ display: 'flex', marginTop: 15 }}>
                          <Input
                            size="large"
                            placeholder="인증코드를 입력해주세요"
                            style={{
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: 50,
                            }}
                            value={inputAuth}
                            onChange={(e) => setInputAuth(e.target.value)}
                            suffix={
                              authCode && authCheck !== 'Y' ? (
                                <p
                                  style={{
                                    color:
                                      timeText !== '만료'
                                        ? '#BDBDBD'
                                        : '#FB4E4E',
                                  }}
                                >
                                  {timeText}
                                </p>
                              ) : authCode && authCheck === 'Y' ? (
                                <p
                                  style={{
                                    color: '#2AC769',
                                  }}
                                >
                                  인증완료
                                </p>
                              ) : (
                                ''
                              )
                            }
                          />
                          <Btn
                            size="large"
                            style={{ height: 50, width: 120, marginLeft: 30 }}
                            onClick={() => handleAuthcodeCheck()}
                          >
                            확인
                          </Btn>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <Button
                    style={{
                      width: '100%',
                      height: '56px',
                      marginTop: '24px',
                      fontSize: '18px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                    // onClick={() => handleFoundIDPW(1)}
                    onClick={() => handleAuthModal(1)}
                  >
                    아이디 찾기
                  </Button>
                  <Button
                    style={{
                      width: '100%',
                      height: '56px',
                      marginTop: '24px',
                      fontSize: '18px',
                      fontWeight: 700,
                      lineHeight: '24px',
                      // display: visiblePhoneCheck ? '' : 'none',
                    }}
                    // onClick={() => handleFoundIDPW(2)}
                    onClick={() => handleAuthModal(2)}
                  >
                    비밀번호 찾기
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      <form name="form_chk" method="post">
        <input type="hidden" name="m" value="checkplusService"></input>
        <input type="hidden" name="EncodeData" value=""></input>
      </form>
    </>
  );
}

Login.defaultProps = {};

export default Login;
