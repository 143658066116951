import React, { useState, useEffect, useMemo } from 'react';
import { Content, Row, Col } from 'components';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { loginUser, loginState, userInfo } from 'atoms/Atoms';
import { Layout, Menu } from 'antd';
import {
  AddressApi,
  AgencyApi,
  AuthApi,
  BannerApi,
  CategoryApi,
  ChapterApi,
  DifficultyApi,
  EventApi,
  EventOfUserApi,
  ExamApi,
  FileApi,
  LectureApi,
  LectureOfUserApi,
  ProductApi,
  ProductCategoryApi,
  ProfileApi,
  ProgramApi,
  PurchaseApi,
  ReviewApi,
  SectionApi,
  SubwayApi,
  UserApi,
} from 'api';
import { getCookie, deleteCookie } from 'utils';
import MessageAlert from 'utils/MessageAlert';
import Users from './Users';
import Agencies from './Agencies';
import Teachers from './Teachers';
// import UsersDetail from './UsersDetail';
import Schedules from './Schedules';
import AgencySchedules from './AgencySchedules';
import Lectures from './Lectures';
import LectureOrders from './LectureOrders';
import Student from './Student';
import Category from './Category';
import Subway from './Subway';
import Address from './Address';
import Difficulty from './Difficulty';
import EventList from './EventList';
import EventRegister from './EventRegister';
import EventDetail from './EventDetail';
import Products from './Products';
import ProductRegister from './ProductRegister';
import ProductCategory from './ProductCategory';
import ProductOrders from './ProductOrders';
import Programs from './Programs';
import Major from './Major';
import TotalCalculate from './TotalCalculate';
import TeacherCalculate from './TeacherCalculate';
import AgencyCalculate from './AgencyCalculate';
import EmailLogs from './EmailLogs';
import SmsLogs from './SmsLogs';
import PointOrders from './PointOrders';
import ProductDelivery from './ProductDelivery';
import RentDelivery from './RentDelivery';
import Banner from './Banner';
import BoardList from './BoardList';
import Logo from '../../assets/brainheal_logo02.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faHouse,
} from '@fortawesome/free-solid-svg-icons';

const AdminRoutes = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  /* ===== Data ===== */
  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const teacherFiles = FileApi.GetTeacherFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  // const schedules = queryClient.getQueryData('getSchedules')?.data;
  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureFiles = FileApi.GetLectureFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const chapters = ChapterApi.GetChapters(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const sections = SectionApi.GetSections(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureOfUsers = LectureOfUserApi.GetLectureOfUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const reviews = ReviewApi.GetReviews(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const agencies = AgencyApi.GetAgencies(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const profiles = ProfileApi.GetUserProfiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const address = AddressApi.GetAddress(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const addressSplit = AddressApi.GetAddressSplit(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const exams = ExamApi.GetExams(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const subways = SubwayApi.GetSubways(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const difficulties = DifficultyApi.GetDifficulties(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const events = EventApi.GetEvents(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const eventOfUsers = EventOfUserApi.GetEventOfUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const products = ProductApi.GetProducts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productCategories = ProductCategoryApi.GetProductCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productFiles = FileApi.GetProductFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const purchases = PurchaseApi.GetPurchases(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const programs = ProgramApi.GetPrograms(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const banner = BannerApi.GetBanners(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  /* ===== STATE ===== */
  // 접속자 확인 (수강생, 강사, 기관)
  const [, setIsLogin] = useRecoilState(loginState);
  const [loginWho, setLoginWho] = useRecoilState(loginUser);
  const userInfoData = useSetRecoilState(userInfo);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const [data, setData] = useState({});
  const [selectedKeys, setSelectedKeys] = useState(['users']);
  // 스크린 크기
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // SideMenu 아이템
  const today = new Date();
  const menus = [
    {
      key: `user-management`,
      icon: null,
      label: '회원관리',
      children: [
        {
          key: 'users',
          label: '수강생 관리',
          icon: null,
          onClick: () => navigate('/admin/users?page=1'),
        },
        {
          key: 'teachers',
          label: '강사 관리',
          icon: null,
          onClick: () => navigate('/admin/teachers?page=1'),
        },
        {
          key: 'agencies',
          label: '기관 관리',
          icon: null,
          onClick: () => navigate('/admin/agencies?page=1'),
        },
      ],
    },
    {
      key: `schedule-management`,
      icon: null,
      label: '일정관리',
      children: [
        {
          key: 'teacher-schedules',
          label: '강사 전체일정 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/teacher-schedules?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }`
            ),
        },
        {
          key: 'agency-schedules',
          label: '기관 전체일정 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/agency-schedules?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }`
            ),
        },
      ],
    },
    {
      key: `product-management`,
      icon: null,
      label: '상품관리',
      children: [
        {
          key: 'lectures',
          label: '강의 관리',
          icon: null,
          onClick: () => navigate('/admin/lectures?page=1'),
        },
        {
          key: 'student',
          label: '수강생 관리',
          icon: null,
          onClick: () => navigate('/admin/student?page=1'),
        },
        {
          key: 'products',
          label: '상품 관리',
          icon: null,
          onClick: () => navigate('/admin/products?page=1'),
        },
      ],
    },
    {
      key: `program-management`,
      icon: null,
      label: '교안관리',
      children: [
        {
          key: 'programs',
          label: '공통 교안 관리',
          icon: null,
          onClick: () => navigate('/admin/programs?page=1'),
        },
      ],
    },
    {
      key: `order-management`,
      icon: null,
      label: '주문결제관리',
      children: [
        {
          key: 'lecture-order',
          label: '강의 결제내역',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/lecture-order?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }&page=1`
            ),
        },
        {
          key: 'product-order',
          label: '상품 결제내역',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/product-order?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }&page=1`
            ),
        },
        {
          key: 'point-order',
          label: '포인트 결제내역',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/point-order?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }&page=1`
            ),
        },
      ],
    },
    {
      key: `delivery-management`,
      icon: null,
      label: '배송상품관리',
      children: [
        {
          key: 'rent-delivery',
          label: '렌탈 배송 상품 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/rent-delivery?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }&page=1`
            ),
        },
        {
          key: 'product-delivery',
          label: '판매 배송 상품 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/product-delivery?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }&page=1`
            ),
        },
      ],
    },
    {
      key: `homepage-management`,
      icon: null,
      label: '홈페이지관리',
      children: [
        {
          key: 'categories',
          label: '카테고리 관리',
          icon: null,
          onClick: () => navigate('/admin/categories'),
        },
        {
          key: 'product-categories',
          label: '상품 카테고리 관리',
          icon: null,
          onClick: () => navigate('/admin/product-categories'),
        },
        {
          key: 'subways',
          label: '지하철 관리',
          icon: null,
          onClick: () => navigate('/admin/subways'),
        },
        {
          key: 'address',
          label: '지역 관리',
          icon: null,
          onClick: () => navigate('/admin/address'),
        },
        {
          key: 'difficulty',
          label: '강의 난이도 관리',
          icon: null,
          onClick: () => navigate('/admin/difficulty'),
        },
        {
          key: 'major',
          label: '분야 관리',
          icon: null,
          onClick: () => navigate('/admin/major'),
        },
        {
          key: 'event-list',
          label: '이벤트 관리',
          icon: null,
          onClick: () => navigate('/admin/event-list'),
        },
        {
          key: 'banner',
          label: '배너 관리',
          icon: null,
          onClick: () => navigate('/admin/banner'),
        },
      ],
    },
    {
      key: `calculate-management`,
      icon: null,
      label: '정산관리',
      children: [
        {
          key: 'total-calculate',
          label: '통합 정산 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/total-calculate?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }&page=1&count=10`
            ),
        },
        {
          key: 'teacher-calculate',
          label: '강사 정산 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/teacher-calculate?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }`
            ),
        },
        {
          key: 'agency-calculate',
          label: '기관 정산 관리',
          icon: null,
          onClick: () =>
            navigate(
              `/admin/agency-calculate?date=${today.getFullYear()}-${
                today.getMonth() + 1
              }`
            ),
        },
      ],
    },
    {
      key: `send-management`,
      icon: null,
      label: '전송 내역 관리',
      children: [
        {
          key: 'email-log',
          label: '이메일 관리',
          icon: null,
          onClick: () => navigate('/admin/email-log?page=1'),
        },
        {
          key: 'sms_log',
          label: '문자 관리',
          icon: null,
          onClick: () => navigate('/admin/sms-log?page=1'),
        },
      ],
    },
    {
      key: `board-management`,
      icon: null,
      label: '게시판 관리',
      children: [
        {
          key: 'board_list',
          label: '게시판 관리',
          icon: null,
          onClick: () => navigate('/admin/board'),
        },
      ],
    },
  ];

  /**
   * 데이터 정제
   * --
   */
  const Data = useMemo(() => {
    const n = {
      users: users?.filter((item) => item?.teacher_check === 'N'),
      teachers: users
        ?.filter((item) => item?.teacher_check === 'Y')
        ?.map((item) => ({
          ...item,
          files: teacherFiles?.filter(
            (subitem) => item?.user_id === subitem?.user_id
          ),
        })),
      // schedules: schedules,
      lectures: lectures?.map((item) => ({
        ...item,
        files: lectureFiles?.filter(
          (subitem) => item?.lecture_id === subitem?.lecture_id
        ),
      })),
      chapters: chapters,
      sections: sections,
      lectureOfUsers: lectureOfUsers,
      reviews: reviews,
      agencies: agencies,
      profiles: profiles,
      address: address,
      exams: exams,
      categories: categories,
      subways: subways,
      difficulties: difficulties,
      addressSplit: addressSplit,
      events: events?.map((item) => ({
        ...item,
        users: eventOfUsers?.filter(
          (subitem) => item?.event_id === subitem?.event_id
        ),
      })),
      products: products?.map((item) => ({
        ...item,
        files: productFiles?.filter(
          (subitem) => item?.product_id === subitem?.product_id
        ),
      })),
      productCategories: productCategories,
      purchases: purchases,
      programs: programs,
      banner: banner,
    };

    return n;
  }, [
    users,
    teacherFiles,
    // schedules,
    lectures,
    chapters,
    sections,
    lectureOfUsers,
    reviews,
    agencies,
    profiles,
    address,
    exams,
    categories,
    subways,
    difficulties,
    addressSplit,
    events,
    eventOfUsers,
    products,
    productCategories,
    productFiles,
    purchases,
    lectureFiles,
    programs,
    banner,
  ]);

  /* ===== FUNCTION ===== */
  /**
   * 토큰 정제
   * --
   */
  const verifyToken = async () => {
    try {
      const result = await AuthApi.verify();
      if (result) {
        if (result.admin_id) {
          return true;
        }
        return false;
      }
      return false;
    } catch (e) {
      console.log('Error !!', e);
      throw e;
    }
  };

  /**
   * 로그아웃
   * --
   */
  const handleLogout = async () => {
    const confirm = window.confirm('로그아웃 하시겠습니까?');

    if (confirm) {
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      setIsLogin(false);
      setLoginWho('');
      userInfoData({});
      navigate('/');
    } else {
      return;
    }
  };

  /* ===== HOOKS ===== */
  /**
   * 관리자가 아닐 시 Return
   * --
   */
  useEffect(() => {
    if (token) {
      verifyToken().then((result) => {
        if (!result) {
          MessageAlert.warning('잘못된 접근입니다.');
          navigate('/');
        }
      });
    } else {
      MessageAlert.warning('잘못된 접근입니다.');
      navigate('/');
    }
  }, [loginWho, navigate, token]);

  /**
   * 데이터 정제
   * --
   */
  useEffect(() => {
    if (Data) {
      setData(Data);
    }
  }, [Data]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  useEffect(() => {
    const path = pathname?.split('/')[3];
    setSelectedKeys(path);
  }, [pathname]);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0}>
      <Layout.Header
        style={
          {
            // background: '#FFFFFF'
          }
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <img src={Logo} alt="로고" />
          </div>
          <ul style={{ display: 'flex', alignItems: 'center' }}>
            <li
              style={{
                fontSize: 14,
                color: '#03A9F4',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              onClick={() => window.open('/', '_blank')}
            >
              홈페이지{' '}
              <span>
                <FontAwesomeIcon icon={faHouse} />
              </span>
            </li>
            <li
              style={{
                fontSize: 14,
                color: '#FB4E4E',
                fontWeight: 600,
                marginLeft: 20,
                cursor: 'pointer',
              }}
              onClick={() => handleLogout()}
            >
              로그아웃{' '}
              <span>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </span>
            </li>
          </ul>
        </div>
      </Layout.Header>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Layout.Sider width={200} style={{ background: 'none' }}>
          <Menu
            items={menus}
            mode="inline"
            theme="dark"
            selectedKeys={selectedKeys}
            style={{
              height: '100%',
            }}
          />
        </Layout.Sider>
        <Layout style={{ background: '#EEEEEE' }}>
          <Content maxWidth={'100%'} padding={50} backgroundColor={'none'}>
            <Row>
              <Col x={24}>
                <Routes>
                  {/* 회원관리 */}
                  <Route
                    path="users"
                    element={<Users data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="agencies"
                    element={<Agencies data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="teachers"
                    element={<Teachers data={data} screenSize={screenSize} />}
                  />
                  {/* //회원관리 */}

                  {/* 일정관리 */}
                  <Route
                    path="teacher-schedules"
                    element={<Schedules data={data} />}
                  />
                  <Route
                    path="agency-schedules"
                    element={<AgencySchedules data={data} />}
                  />
                  {/* //일정관리 */}

                  {/* 상품관리 */}
                  <Route path="lectures" element={<Lectures data={data} />} />
                  <Route path="student" element={<Student data={data} />} />
                  <Route path="products" element={<Products data={data} />} />
                  <Route
                    path="product-regist"
                    element={<ProductRegister data={data} />}
                  />
                  <Route path="programs" element={<Programs data={data} />} />
                  {/* //상품관리 */}

                  {/* 주문결제관리 */}
                  <Route
                    path="lecture-order"
                    element={<LectureOrders data={data} />}
                  />
                  <Route
                    path="product-order"
                    element={<ProductOrders data={data} />}
                  />
                  <Route
                    path="point-order"
                    element={<PointOrders data={data} />}
                  />

                  {/* 주문결제관리 */}
                  <Route
                    path="product-delivery"
                    element={<ProductDelivery data={data} />}
                  />
                  <Route
                    path="rent-delivery"
                    element={<RentDelivery data={data} />}
                  />
                  {/* //주문결제관리 */}

                  {/* 홈페이지관리 */}
                  <Route
                    path="categories"
                    element={<Category data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="product-categories"
                    element={
                      <ProductCategory data={data} screenSize={screenSize} />
                    }
                  />
                  <Route
                    path="subways"
                    element={<Subway data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="address"
                    element={<Address data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="difficulty"
                    element={<Difficulty data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="major"
                    element={<Major data={data} screenSize={screenSize} />}
                  />

                  <Route path="/event-list">
                    <Route
                      index
                      element={
                        <EventList data={data} screenSize={screenSize} />
                      }
                    />
                    <Route
                      path=":eventId"
                      element={
                        <EventDetail data={data} screenSize={screenSize} />
                      }
                    />
                  </Route>
                  <Route
                    path="event-regist"
                    element={
                      <EventRegister data={data} screenSize={screenSize} />
                    }
                  />
                  <Route
                    path="banner"
                    element={<Banner data={data} screenSize={screenSize} />}
                  />
                  {/* //홈페이지 관리 */}

                  {/* 정산관리 */}
                  <Route
                    path="total-calculate"
                    element={
                      <TotalCalculate data={data} screenSize={screenSize} />
                    }
                  />
                  <Route
                    path="teacher-calculate"
                    element={
                      <TeacherCalculate data={data} screenSize={screenSize} />
                    }
                  />
                  <Route
                    path="agency-calculate"
                    element={
                      <AgencyCalculate data={data} screenSize={screenSize} />
                    }
                  />
                  {/* //정산관리 */}

                  {/* 로그 관리 */}
                  <Route
                    path="email-log"
                    element={<EmailLogs data={data} screenSize={screenSize} />}
                  />
                  <Route
                    path="sms-log"
                    element={<SmsLogs data={data} screenSize={screenSize} />}
                  />
                  {/* //로그 관리 */}

                  {/* 게시판 관리 */}
                  <Route path="/board">
                    <Route
                      index
                      element={
                        <BoardList data={data} screenSize={screenSize} />
                      }
                    />
                  </Route>
                  {/* // 게시판 관리 */}
                </Routes>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Content>
  );
};

export default AdminRoutes;
