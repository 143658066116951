import React, { useState, useEffect } from 'react';
import LectureListPresenter from './LectureListPresenter';
import { AnswerExamApi, ExamApi, FileApi, LectureApi } from 'api';
import { getCookie } from 'utils';

const LectureListContainer = ({ screenSize }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const uid = getCookie('USER');
  const [newData, setNewData] = useState([]);

  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureFiles = FileApi.GetLectureFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const exams = ExamApi.GetExams(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const answers = AnswerExamApi.GetAnswerExams(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  // console.log('Data', Data);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (uid && lectures?.length && exams?.length && answers?.length) {
      const l = lectures?.filter((item) => item.user_id === parseInt(uid));
      const ex = l?.reduce((acc, item) => {
        const filteredLecture = exams?.filter((subitem) => {
          return subitem.lecture_id === item.lecture_id;
        });
        return acc.concat(filteredLecture);
      }, []);

      const newExams = ex?.map((item) => ({
        ...item,
        answers: answers?.filter(
          (subitem) => item?.exam_id === subitem?.exam_id
        ),
      }));

      const newLectures = l?.map((item) => ({
        ...item,
        files: lectureFiles?.filter(
          (file) => item?.lecture_id === file?.lecture_id
        ),
        exams: newExams?.filter(
          (subitem) => item.lecture_id === subitem.lecture_id
        ),
      }));

      setNewData(newLectures);
    }
  }, [uid, lectures, exams, answers, lectureFiles]);

  /* ===== RENDER ===== */
  return <LectureListPresenter lectures={newData} screenSize={screenSize} />;
};

LectureListContainer.defaultProps = {};

export default LectureListContainer;
