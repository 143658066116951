import React from 'react';
import ProfilePresenter from './ProfilePresenter';
import {
  AddressApi,
  FileApi,
  PreferenceApi,
  ProfileApi,
  SubwayApi,
  UserApi,
  MajorApi,
  SNSApi,
  CategoryApi,
} from 'api';
import { getCookie } from 'utils';

const ProfileContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const uid = getCookie('USER');
  const area = AddressApi.GetAddressSplit(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
    cacheTime: 'Infinity',
  })?.data;
  const subway = SubwayApi.GetSubways(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
    cacheTime: 'Infinity',
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const user = UserApi.GetUser(uid, null, {
    enabled: !!uid,
    select: (res) => {
      const { status, message, data } = res;

      if (status !== 200) {
        console.error(message);
        return {};
      }

      return data;
    },
  });
  const teacherFiles = FileApi.GetTeacherFiles(null, {
    select: (res) => {
      const { status, message, data } = res;

      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const profiles = ProfileApi.GetUserProfiles(null, {
    select: (res) => {
      const { status, message, data } = res;

      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const preferenceArea = PreferenceApi.GetPreferenceAreaUsers(
    {
      user_id: parseInt(uid),
    },
    {
      select: (res) => {
        const { status, message, data } = res;

        if (status !== 200) {
          console.error(message);
          return [];
        }

        return data;
      },
      enabled: !!uid,
    }
  )?.data;
  const preferenceSubway = PreferenceApi.GetPreferenceSubwayUsers(
    {
      user_id: parseInt(uid),
    },
    {
      select: (res) => {
        const { status, message, data } = res;

        if (status !== 200) {
          console.error(message);
          return [];
        }

        return data;
      },
      enabled: !!uid,
    }
  )?.data;

  const major = MajorApi.GetUserMajors(
    {
      user_id: parseInt(uid),
    },
    {
      select: (res) => {
        const { status, message, data } = res;

        if (status !== 200) {
          console.error(message);
          return [];
        }

        return data;
      },
      enabled: !!uid,
    }
  )?.data;
  const sns = SNSApi.GetSNSUsers(
    {
      user_id: parseInt(uid),
    },
    {
      select: (res) => {
        const { status, message, data } = res;

        if (status !== 200) {
          console.error(message);
          return [];
        }

        return data;
      },
      enabled: !!uid,
    }
  )?.data;

  const Data = React.useMemo(() => {
    const userProfile = profiles?.find(
      (profile) => profile?.user_id === parseInt(uid)
    );
    const userFiles = teacherFiles?.filter(
      (file) => file?.user_id === parseInt(uid)
    );
    const majorItem = major?.map((item) => {
      const category = categories?.find(
        (subitem) => subitem?.category_id === item?.major
      );
      const categoryDetail = categories?.find(
        (subitem) => subitem?.category_id === item?.major_detail
      );
      return {
        ...item,
        major_name: category?.category_name,
        major_detail_name: categoryDetail?.category_name,
      };
    });

    return {
      user: user?.data,
      profile: {
        ...userProfile,
        gallery: userFiles?.filter((item) => item?.type === 'G'),
        attachment: userFiles?.filter((item) => item?.type === 'E'),
        certificate: userFiles?.filter((item) => item?.type === 'C'),
        profileImage: userFiles?.filter((item) => item?.type === 'P')?.pop(),
      },
      preference_area: preferenceArea?.map((item) => ({
        ...item,
        ...(area &&
          area.local &&
          area.local?.filter(
            (subitem) => subitem.local_id === item.local_id
          )[0]),
        ...(area &&
          area.city &&
          area.city?.filter((subitem) => subitem.city_id === item.city_id)[0]),
        ...(area &&
          area.district &&
          area.district?.filter(
            (subitem) => subitem.district_id === item.district_id
          )[0]),
      })),
      preference_subway: preferenceSubway?.map((item) => ({
        ...item,
        ...(subway &&
          subway?.filter((subitem) => subitem.subway_id === item.subway_id))[0],
      })),
      sns: sns && sns.length && sns,
      major: majorItem && majorItem.length && majorItem,
    };
  }, [
    user,
    profiles,
    teacherFiles,
    preferenceArea,
    preferenceSubway,
    sns,
    major,
    area,
    subway,
    uid,
    categories,
  ]);
  // console.log('data', data);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <ProfilePresenter
      user={Data?.user}
      profile={Data?.profile}
      area={Data?.preference_area}
      subway={Data?.preference_subway}
      sns={Data?.sns}
      major={Data?.major}
    />
  );
};

ProfileContainer.defaultProps = {};

export default ProfileContainer;
