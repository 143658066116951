import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import './BoardDetailLayout.style.css';
import { Content, Row, Col, Title, ModalLayout } from 'components';
import { MenuLayout } from '../BoardLayout/components';
import { BoardApi, LectureOfUserApi, UserApi, LectureApi, FileApi } from 'api';
import { decrypt } from 'utils/Crypto';
import { DetailView, CommentList, ArticleList } from './components';
import MessageAlert from 'utils/MessageAlert';
import { Pagination, Button as Btn } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const BoardDetailLayout = (props) => {
  /* ===== PROPS ===== */
  const { navigation, userInfoData, loginWho } = props;

  /* ===== ROUTE ===== */
  const { boardId } = useParams();
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const type = searchParams.get('type');

  /* ===== MUTATE ===== */
  const deleteBoard = BoardApi.DeleteBoard();

  /* ===== DATA ===== */
  const { data: board, refetch: boardRefetch } = BoardApi.GetBoards(
    {
      lecture_id: decrypt(lectureId),
    },
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );
  const { data: lecture, refetch: lectureRefetch } = LectureApi.GetLecture(
    decrypt(lectureId),
    null,
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );
  const { data: student, refetch: studentRefetch } =
    LectureOfUserApi.GetLectureOfUsers(
      {
        lecture_id: decrypt(lectureId),
      },
      {
        enabled: !!lectureId,
        select: (data) => data?.data,
      }
    );
  const { data: teacher, refetch: teacherRefetch } = UserApi.GetUser(
    lecture?.user_id,
    null,
    {
      enabled: !!lecture,
      select: (data) => data?.data,
    }
  );
  const studentFiles = FileApi.GetTeacherFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  /* ===== STATE ===== */
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const count = 10;
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 반응형 메뉴
  const [isOpened, setIsOpened] = useState(false);
  // 접속 유저 게시판 권한
  const [boardAuth, setBoardAuth] = useState('READER');
  // 게시판 메뉴 설정
  const boardMenu = useMemo(() => {
    const menu = [
      {
        key: 'INQUIRY',
        title: '문의',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=inquiry`,
      },
      {
        key: 'DISCUSSION',
        title: '토론',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=discussion`,
      },
      {
        key: 'ASSIGNMENT',
        title: '과제',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=assignment`,
      },
    ];

    return menu;
  }, [lectureId]);

  /**
   * 게시판 데이터 정제
   * --
   */
  const boardData = useMemo(() => {
    // 프로필 사진 정제
    const profiles = studentFiles?.filter((item) => item?.type === 'P');

    // 게시판 정보 정제
    const newBoards = board
      ?.filter((item) => item?.type === type?.toLocaleUpperCase())
      ?.map((item) => {
        const { comment, ...d } = item;

        return {
          ...d,
          ...student?.find((user) => item?.user_id === user?.user_id),
          writer:
            item?.user_id === 0
              ? '관리자'
              : lecture?.user_id === item?.user_id
              ? `${teacher?.name} 강사님`
              : student?.find((user) => item?.user_id === user?.user_id)?.name,
          profile: profiles
            ?.filter((file) => file?.user_id === item?.user_id)
            ?.pop(),
          board_created_at: item?.created_at,
          board_update_at: item?.updated_at,
          comment: comment?.map((subitem) => ({
            ...subitem,
            writer: {
              ...student?.find((user) => subitem?.user_id === user?.user_id),
              writer:
                subitem?.user_id === 0
                  ? '관리자'
                  : lecture?.user_id === subitem?.user_id
                  ? `${teacher?.name} 강사님`
                  : student?.find((user) => subitem?.user_id === user?.user_id)
                      ?.name,
              profile: profiles
                ?.filter((file) => file?.user_id === subitem?.user_id)
                ?.pop(),
            },
          })),
        };
      })
      ?.sort(
        (a, b) => new Date(b?.board_created_at) - new Date(a?.board_created_at)
      );
    // 게시판 파일 정제
    const files = board?.reduce((acc, cur) => {
      let arr = acc;
      if (cur?.board_file?.length) {
        acc = [...arr, ...cur?.board_file];
        return acc;
      } else {
        acc = arr;
        return acc;
      }
    }, []);

    const boardDetail = newBoards?.find(
      (item) => item?.board_id === parseInt(decrypt(boardId))
    );

    // 댓글 데이터 정제
    const newComment = boardDetail?.comment?.map((item) => {
      const { writer, ...d } = item;

      return {
        ...d,
        name: writer?.writer,
        profile: writer?.profile,
      };
    });
    let parents = [],
      children = [];
    newComment?.forEach((item) => {
      if (item?.parents_id) {
        children.push(item);
      } else {
        parents.push(item);
      }
    });

    /**
     * NOTE: 댓글 정렬
     * Parents: 댓글 등록일 내림차순 정렬
     * Children: 대댓글 등록일 오름차순 정렬
     */
    const newCommentList = parents
      ?.map((item) => ({
        ...item,
        children: children
          ?.filter((subitem) => item?.comment_id === subitem?.parents_id)
          ?.sort((a, b) => new Date(a?.created_at) - new Date(b?.created_at)),
      }))
      ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

    const newData = {
      teacher: teacher,
      boardList: newBoards,
      board: boardDetail,
      files: files,
      lecture: lecture,
      student: student,
      comment: newCommentList,
    };

    return newData;
  }, [board, lecture, teacher, student, boardId, type, studentFiles]);

  /* ===== RENDER ===== */
  /**
   * 데이터 Refetch
   * --
   */
  useEffect(() => {
    if (lectureId) {
      boardRefetch();
      lectureRefetch();
      studentRefetch();
    }
  }, [lectureId, boardRefetch, lectureRefetch, studentRefetch]);

  /**
   * 강사 데이터 Refetch
   */
  useEffect(() => {
    if (lecture) {
      teacherRefetch();
    }
  }, [lecture, teacherRefetch]);

  /**
   * 데이터 값 렌더링
   * --
   */
  useEffect(() => {
    setData(boardData);
  }, [boardData]);

  /**
   * 게시판 권한 체크
   * NOTE
   * WRITER: 수정, 삭제, 등록, 읽기 권한
   * READER: 등록, 읽기 권한
   * --
   */
  useEffect(() => {
    if (loginWho === 'admin') {
      setBoardAuth('WRITER');
    } else if (data?.teacher?.user_id === userInfoData?.user_id) {
      setBoardAuth('WRITER');
    } else {
      setBoardAuth('READER');
    }
  }, [loginWho, data, userInfoData]);
  // console.log('data', data);

  /**
   * 기관회원 + 비구매고객 접근제한
   * --
   */
  useEffect(() => {
    if (loginWho && userInfoData && student && lecture) {
      // 기관회원 접근 제한
      if (loginWho === 'agency') {
        MessageAlert.warning('접근 권한이 없습니다!');
        navigation('/');
      }

      // 등록한 강사인지 체크

      // 구매고객인지 체크
      const findStudent = student?.some(
        (item) => item?.user_id === userInfoData?.user_id
      );
      if (!findStudent && (loginWho === 'teacher' || loginWho === 'agency')) {
        if (lecture?.user_id === userInfoData?.user_id) {
          return;
        }
        MessageAlert.warning('접근 권한이 없습니다!');
        navigation('/');
      }
    }
  }, [loginWho, userInfoData, student, navigation, lecture]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== FUNCTION ===== */
  /**
   * 게시글 삭제
   * --
   */
  const handleDeleteBoard = () => {
    const confirm = window.confirm('정말 게시글을 삭제하시겠습니까?');

    if (confirm) {
      try {
        deleteBoard.mutate({
          board_id: data?.board?.board_id,
        });

        MessageAlert.success('게시글이 성공적으로 삭제되었습니다.');
        navigation(-1);
      } catch (err) {
        MessageAlert.error('게시글을 삭제하는데 실패하였습니다.');
      }
    } else {
      MessageAlert.warning('취소되었습니다.');
    }
  };

  return (
    <>
      <Content maxWidth={1500}>
        <Row style={{ marginBottom: 30 }}>
          <Col x={21} md={24}>
            <Title size={screenSize >= 768 ? 2 : 3}>
              {data?.lecture?.title} 강의 게시판
            </Title>
          </Col>
          <Col x={3} md={0}>
            <Btn
              onClick={() => setIsOpened(!isOpened)}
              style={{
                float: 'right',
                background: '#E3A4AF',
                border: '1px solid #E3A4AF',
                color: '#FFFFFF',
              }}
            >
              <MenuOutlined />
            </Btn>
          </Col>
        </Row>
        <Row>
          <Col x={0} md={4}>
            <MenuLayout menu={boardMenu} currentType={type} />
          </Col>
          <Col x={24} md={20}>
            {/* 게시글 내용 */}
            <div>
              <DetailView data={data?.board} auth={boardAuth} />
            </div>
            {/* /게시글 내용 */}

            {/* 버튼 */}
            <div className="board_detail_button_layout">
              <div>
                <button
                  style={{
                    ...styles.button,
                    background: '#666',
                    border: '1px solid #666',
                  }}
                  onClick={() => navigation(-1)}
                >
                  목록
                </button>
              </div>
              {(parseInt(data?.board?.user_id) ===
                parseInt(userInfoData?.user_id) ||
                boardAuth === 'WRITER') && (
                <div>
                  <button
                    style={{
                      ...styles.button,
                      background: '#AB5476',
                      border: '1px solid #AB5476',
                      marginRight: 10,
                    }}
                    onClick={() =>
                      navigation(
                        `/popup/board/write?lecture=${encodeURIComponent(
                          lectureId
                        )}&type=${type}`,
                        {
                          state: {
                            data: { ...data, detail: data?.board },
                          },
                        }
                      )
                    }
                  >
                    수정
                  </button>
                  <button
                    style={{
                      ...styles.button,
                      background: '#FB4E4E',
                      border: '1px solid #FB4E4E',
                    }}
                    onClick={() => handleDeleteBoard()}
                  >
                    삭제
                  </button>
                </div>
              )}
            </div>
            {/* /버튼 */}

            {/* 다음글/이전글 */}
            <div>
              <ArticleList
                current={parseInt(decrypt(boardId))}
                data={data?.boardList}
              />
            </div>
            {/* /다음글/이전글 */}

            {/* 댓글 */}
            <div>
              {data?.board?.type !== 'ASSIGNMENT' ? (
                <>
                  <CommentList
                    data={data?.board}
                    comments={data?.comment}
                    auth={boardAuth}
                    count={count}
                    page={page}
                  />
                  {data?.comment?.length ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 15,
                      }}
                    >
                      <Pagination
                        pageSize={count}
                        current={page}
                        total={data?.comment?.length}
                        onChange={(e) => setPage(e)}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                data?.board?.type === 'ASSIGNMENT' &&
                (parseInt(data?.board?.user_id) ===
                  parseInt(userInfoData?.user_id) ||
                  boardAuth === 'WRITER') && (
                  <>
                    <CommentList
                      data={data?.board}
                      comments={data?.comment}
                      auth={boardAuth}
                      count={count}
                      page={page}
                    />
                    {data?.comment?.length ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 15,
                        }}
                      >
                        <Pagination
                          pageSize={count}
                          current={page}
                          total={data?.comment?.length}
                          onChange={(e) => setPage(e)}
                        />
                      </div>
                    ) : null}
                  </>
                )
              )}
            </div>
            {/* /댓글 */}
          </Col>
        </Row>
      </Content>

      {/* 반응형 메뉴 모달 */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'60%'}
        height={'100%'}
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={10}>
          <Row>
            <Col x={24}>
              <MenuLayout menu={boardMenu} currentType={type} />
            </Col>
          </Row>
        </Content>
      </ModalLayout>
      {/* /반응형 메뉴 모달 */}
    </>
  );
};

const styles = {
  button: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 700,
    height: '45px',
    widht: '80px',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '10px',
  },
};

export default BoardDetailLayout;
