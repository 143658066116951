import React from 'react';
import LectureOrdersPresenter from './LectureOrdersPresenter';
import { getCookie } from 'utils';
import {
  CategoryApi,
  FileApi,
  LectureApi,
  LectureOfUserApi,
  ReviewApi,
  UserApi,
} from 'api';

const LectureOrdersContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureFiles = FileApi.GetLectureFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const reviews = ReviewApi.GetReviews(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureOfUsers = LectureOfUserApi.GetLectureOfUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const idx = parseInt(getCookie('USER'));
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <LectureOrdersPresenter
      lectures={lectures}
      lectureFiles={lectureFiles}
      users={users}
      reviews={reviews}
      lectureOfUsers={lectureOfUsers}
      categories={categories}
      idx={idx}
    />
  );
};

LectureOrdersContainer.defaultProps = {};

export default LectureOrdersContainer;
