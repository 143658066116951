import React, { useEffect, useState } from 'react';
import {
  Content,
  Row,
  Col,
  Card,
  Title,
  LectureCard,
  ModalLayout,
  BottomLayout,
  Button as Btn,
} from 'components';
import { Button, Tag, Divider, Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  stringToMoneyFormat,
  getCookie,
  deleteCookie,
  getFirstImageFromContent,
  HOST_URL,
  dateFormatter,
} from 'utils';
import { encrypt, decrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';
import {
  ProductApi,
  ProductCartApi,
  ProgramApi,
  PurchaseApi,
  FileApi,
  ProductCategoryApi,
} from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import ProgramLayout from './PopupRoot/ProgramLayout';
import axios from 'axios';
import background from 'assets/bg/lecture_bg.png';
import { STORE_URL } from 'utils';

const styles = {
  cardButton: {
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};

const ShopDetail = () => {
  /* ===== Props ===== */

  /* ===== Mutate ===== */
  /**
   * 장바구니 등록 DB
   * --
   */
  const createProductCart = ProductCartApi.CreateProductCart({
    onSuccess: (data) => {
      try {
        MessageAlert.success('성공적으로 등록되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });
  /* ===== Router ===== */
  const navigate = useNavigate();
  const { productId } = useParams();

  /* ===== DATA ===== */
  const user_id = parseInt(getCookie('USER'));

  const products = ProductApi.GetProducts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productFiles = FileApi.GetProductFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productCategories = ProductCategoryApi.GetProductCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productCarts = ProductCartApi.GetProductCarts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const programs = ProgramApi.GetPrograms(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const today = new Date();
  let alredyCartList = [];

  productCarts
    ?.filter((item) => item.user_id === user_id)
    .forEach((subitem) => {
      alredyCartList.push(subitem.product_id);
    });

  //상품데이터 정제
  const Products = products?.map((item) => ({
    ...item,
    productFile:
      productFiles &&
      productFiles
        ?.filter((subitem) => item.product_id === subitem.product_id)
        ?.pop(),
    ...productCategories
      ?.filter(
        (subitem) => item.product_category_id === subitem.product_category_id
      )
      ?.pop(),
    productProgram:
      programs &&
      programs
        ?.filter((subitem) => item?.program_id === subitem?.program_id)
        ?.pop(),
  }));

  const NewProduct = Products?.filter(
    (i) => i.product_id === parseInt(decrypt(productId))
  )?.pop();

  const { data: recommend, refetch: recommendRefetch } =
    ProductApi.GetRecommendProducts(
      {
        product_category_id: NewProduct?.product_category_id,
        product_id: NewProduct?.product_id,
      },
      {
        enabled: !!NewProduct,
        cacheTime: 0,
      }
    );
  const purchaseUser = PurchaseApi.GetPurchases(
    {
      product_id: NewProduct?.product_id,
    },
    {
      enabled: !!NewProduct,
      cacheTime: 0,
    }
  )?.data?.data;

  /* ===== State ===== */
  const loginWho = useRecoilValue(loginUser);
  // const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  // const uid = getCookie('USER');
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [isOpened, setIsOpened] = useState(false);
  const [recommendList, setRecommendList] = useState([]);
  const [alredyPayments, setAlerdyPayments] = useState(null);
  const [programModal, setProgramModal] = useState(false);
  const [programValue, setProgramValue] = useState({});

  /* ===== Hooks ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 추천 상품 조회
   */
  useEffect(() => {
    if (NewProduct) recommendRefetch();
    // eslint-disable-next-line
  }, [products, recommendRefetch]);

  /**
   * 추천 상품 조회
   */
  useEffect(() => {
    if (recommend?.status === 200) {
      const newList = recommend?.data?.map((item) => ({
        ...item,
        thumbnail: getFirstImageFromContent(item?.content),
        productFile:
          productFiles &&
          productFiles
            ?.filter((subitem) => item.product_id === subitem.product_id)
            ?.pop(),
        ...productCategories
          ?.filter(
            (subitem) =>
              item.product_category_id === subitem.product_category_id
          )
          ?.pop(),
      }));

      setRecommendList(newList);
    }
  }, [recommend, productFiles, productCategories]);

  /**
   * 파일 상품일 경우 이미 구매한 상품인지 체크
   * --
   */
  useEffect(() => {
    if (NewProduct?.sales_type === 'F') {
      const isCheck = purchaseUser
        ?.filter((item) => item.user_id === user_id)
        .pop();
      if (isCheck) {
        setAlerdyPayments(isCheck);
      } else {
        setAlerdyPayments(null);
      }
    }
  }, [user_id, NewProduct, purchaseUser]);

  /* ===== Functions ===== */
  /**
   * 수강바구니/좋아요 등록
   * --
   * @param {*} productId
   * @param {*} value : 1- 저장 후 바로 결제창 이동 / 2 - cart 저장
   */
  const handleCreateProductCart = (value) => {
    if (loginWho === '') {
      if (
        window.confirm(
          '로그인 후 사용가능 합니다. 로그인 페이지로 이동하시겠습니까?'
        )
      ) {
        const currentPath = window.location.hash.split('#');
        deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
        deleteCookie('USER', {});
        navigate(`/login`, { state: { prev: `${currentPath[1]}` } });
      }
    } else {
      const wording = value === 1 ? '결제' : '장바구니';
      const movePath = '/mypage/product/cart';
      if (alredyCartList.includes(parseInt(decrypt(productId)))) {
        if (
          window.confirm(
            '이미 담은 상품입니다. ' + wording + ' 페이지로 이동하시겠습니까?'
          )
        ) {
          navigate(movePath);
        }
      } else {
        try {
          if (loginWho === 'agency') {
            createProductCart.mutate({
              agency_id: user_id,
              product_id: parseInt(decrypt(productId)),
            });
          } else {
            createProductCart.mutate({
              user_id: user_id,
              product_id: parseInt(decrypt(productId)),
            });
          }
        } catch (e) {
          MessageAlert.error('시스템 오류가 일어났습니다.');
          throw e;
        }
        if (window.confirm(wording + ' 페이지로 이동하시겠습니까?')) {
          navigate(movePath);
        }
      }
    }
  };

  /**
   * 파일 다운로드
   * --
   */
  const handleFileDownload = async (item) => {
    if (item) {
      const url = `brainheal/product/${item?.file_url}`;

      try {
        const res = await axios.post(`${HOST_URL}/file/blob`, {
          key: url,
        });
        const blob = res.data.data.data;
        const uint8Array = new Uint8Array(blob);
        const fileData = new File([uint8Array], item.file_name);
        const downloadURL = window.URL.createObjectURL(fileData);
        const a = document.createElement('a');
        a.href = downloadURL;
        a.download = item.file_name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadURL);
      } catch (err) {
        console.log('파일 다운로드 에러!', err);
        throw err;
      }
    } else {
      MessageAlert.error('다운로드 실패! 관리자에게 문의해주세요!');
    }
  };
  /**
   * 링크 공유
   * --
   */
  const handleLinkShare = async () => {
    // REVIEW: 배송지 미선택 시 추가
    const nowUrl = window.location.href;
    if (navigator.clipboard !== undefined) {
      // clipboard API 사용
      // localhost 환경 or https 에서만 작동
      try {
        await window.navigator.clipboard
          .writeText(nowUrl)
          .then(() => MessageAlert.success('복사 완료!'));
      } catch (error) {
        MessageAlert.error('복사 실패');
        console.log('error!!', error);
      }
    } else {
      // execCommand
      const textArea = document.createElement('textarea');
      textArea.value = nowUrl;
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      try {
        document.execCommand('copy');
      } catch (error) {
        MessageAlert.error('복사 실패');
        console.log('error!!', error);
      }
      textArea.setSelectionRange(0, 0);
      document.body.removeChild(textArea);
      MessageAlert.success('복사 완료!');
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={0} maxWidth={'100%'} style={{ margin: '0px 0 84px' }}>
        {/* <br /> */}
        {/* ===== /배너 ===== */}
        <Content
          padding={
            screenSize >= 1200 ? 0 : screenSize >= 768 ? '0 30px' : '0 15px'
          }
          maxWidth={1200}
        >
          <Title style={{ margin: '30px 0' }}>
            {' '}
            상품명 : {NewProduct?.product_name}
          </Title>

          <Row>
            <Col x={24} lg={16}>
              <Content maxWidth={'100%'} padding={0}>
                <Image
                  src={
                    NewProduct?.thumbnail_url
                      ? `${STORE_URL}/product/${NewProduct?.thumbnail_url}`
                      : background
                  }
                  width={'100%'}
                  height={'100%'}
                  preview={false}
                />
              </Content>
            </Col>
            <Col x={0} lg={1}></Col>
            <Col x={0} lg={7}>
              <Card shadow>
                <Title size={5} align="left">
                  <small>
                    [{' '}
                    {
                      productCategories
                        ?.filter(
                          (item) =>
                            item.product_category_id === NewProduct?.parents_id
                        )
                        ?.pop()?.product_category_name
                    }{' '}
                    ]
                  </small>

                  <br />
                  {NewProduct?.product_name}
                </Title>
                <div>
                  <Tag>{NewProduct?.product_category_name}</Tag>
                  <Tag>바로 결제 가능</Tag>
                </div>
                <br />
                <div>
                  <Title
                    size={5}
                    subButton={
                      <>
                        <Title align="right">
                          {/* <small>25%</small>{' '} */}
                          {stringToMoneyFormat(
                            NewProduct?.price,
                            NewProduct?.is_cash === 'Y' ? '원' : 'P'
                          )}
                        </Title>
                      </>
                    }
                  >
                    판매금액
                  </Title>
                </div>
                <Divider style={{ margin: '10px 0' }} />
                {/* <div style={{ display: 'flex' }}>
                <Button block style={{ margin: '0 5px' }}>
                  공유
                </Button>
                <Button block>선물</Button>
              </div> */}
                <Button
                  block
                  size="large"
                  onClick={() => handleLinkShare()}
                  disabled={NewProduct?.num === 0}
                >
                  공유하기
                </Button>
                {/* 다운로드 날짜 만료시 다시 구매처리 */}
                {today - new Date(alredyPayments?.created_at) <=
                7 * 24 * 60 * 60 * 1000 ? (
                  <div>
                    <p style={{ fontWeight: 700 }}>다운로드 가능 기간</p>
                    <p>
                      {dateFormatter(alredyPayments?.created_at, 'YY-MM-DD')} ~{' '}
                      {dateFormatter(
                        new Date(alredyPayments?.created_at).setDate(
                          new Date(alredyPayments?.created_at).getDate() + 7
                        ),
                        'YY-MM-DD'
                      )}
                    </p>
                    <Button
                      block
                      size="large"
                      type="primary"
                      style={{
                        marginTop: 7,
                        background:
                          NewProduct?.num === 0 ? '#F5F5F5' : '#E3A4AF',
                        border: 'none',
                      }}
                      onClick={() =>
                        handleFileDownload(NewProduct?.productFile)
                      }
                      disabled={NewProduct?.num === 0}
                    >
                      파일 다운로드
                    </Button>
                    {NewProduct?.productProgram ? (
                      <Button
                        block
                        size="large"
                        type="primary"
                        style={{
                          marginTop: 7,
                          background:
                            NewProduct?.num === 0 ? '#F5F5F5' : '#E3A4AF',
                          border: 'none',
                        }}
                        onClick={() => {
                          setProgramModal(true);
                          setProgramValue({
                            program_id: NewProduct?.productProgram?.program_id,
                            ...NewProduct?.productProgram?.content,
                          });
                        }}
                        disabled={NewProduct?.num === 0}
                      >
                        교안 다운로드
                      </Button>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <Button
                      block
                      size="large"
                      type="primary"
                      style={{
                        marginTop: 7,
                        background:
                          NewProduct?.num === 0 ? '#F5F5F5' : '#E3A4AF',
                      }}
                      onClick={() =>
                        handleCreateProductCart(1, NewProduct?.sales_type)
                      }
                      disabled={NewProduct?.num === 0}
                    >
                      {NewProduct?.num === 0 ? '품절' : '구매하기'}
                    </Button>
                    <Button
                      block
                      size="large"
                      type="primary"
                      style={{
                        marginTop: 7,
                        background:
                          NewProduct?.num === 0 ? '#F5F5F5' : '#AB5476',
                        border: 'none',
                      }}
                      onClick={() =>
                        handleCreateProductCart(2, NewProduct?.sales_type)
                      }
                      disabled={NewProduct?.num === 0}
                    >
                      {NewProduct?.num === 0 ? '품절' : '장바구니 담기'}
                    </Button>
                  </>
                )}
              </Card>
            </Col>
            <br />
            <Col x={24}>
              <Content
                maxWidth={'100%'}
                padding={'30px 0px'}
                style={{ borderTop: '2px solid #AB5476', marginTop: 30 }}
              >
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: NewProduct?.content }}
                  ></div>
                  {/* {NewProduct?.productFiles?.length > 0 ? (
                    <Image
                      src={`${STORE_URL}/product/${NewProduct?.productFiles[0]?.file_url}`}
                      width={'100%'}
                      height={'100%'}
                      style={{ margin: '20px 0' }}
                      preview={false}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '400px',
                        alignItems: 'center',
                        border: '1px solid #f0f0f0',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )} */}
                </div>
              </Content>
            </Col>

            <Col x={24} style={{ margin: '20px 0' }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title size={3}>이런 상품은 어떠세요?</Title>
                <Row>
                  {recommendList && recommendList?.length > 0 ? (
                    recommendList?.map((_item, _index) => (
                      <Col
                        x={12}
                        xl={6}
                        lg={8}
                        style={{ padding: 0 }}
                        key={`mn2_${_index}`}
                      >
                        <Card
                          style={styles.contentCardButton}
                          bodyStyle={{ padding: 10 }}
                          onClick={() =>
                            navigate(
                              `/shop/detail/${encodeURIComponent(
                                encrypt(_item?.product_id)
                              )}`
                            )
                          }
                        >
                          <LectureCard
                            thumbnail={_item?.thumbnail && _item?.thumbnail}
                            title={_item?.product_name}
                            teacher={_item?.num ? '수량 :' + _item?.num : ''}
                            category={_item?.product_category_name}
                            price={_item?.price}
                            productCheck={true}
                          />
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col x={24}>
                      <Content
                        maxWidth={'100%'}
                        height={250}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: ' center',
                        }}
                      >
                        <Title>비슷한 카테고리의 상품이 없습니다.</Title>
                      </Content>
                    </Col>
                  )}
                </Row>
              </Content>
            </Col>
            {/* <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <div>
                  <Divider
                    style={{
                      margin: '10px 0',
                      borderTop: '2px solid rgba(0,0,0,.16) ',
                    }}
                  />
                  <Title style={{ margin: '20px 0' }}> 상품 상세내용 </Title>
                  {NewProduct?.productFiles?.length > 0 ? (
                    NewProduct?.productFiles?.map((item, index) => (
                      <React.Fragment key={`image-${index}`}>
                        <Image
                          src={`${STORE_URL}/product/${item?.file_url}`}
                          width={'100%'}
                          height={'100%'}
                          style={{ margin: '10px 0' }}
                          preview={false}
                        />
                      </React.Fragment>
                    ))
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '400px',
                        alignItems: 'center',
                        border: '1px solid #f0f0f0',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </div>
              </Content>
            </Col> */}
          </Row>
        </Content>
      </Content>

      {screenSize >= 992 ? null : (
        <BottomLayout shadow={true}>
          <Content maxWidth={'100%'} padding={0}>
            {/* 다운로드 날짜 만료시 다시 구매처리(반응형) */}
            {today - new Date(alredyPayments?.created_at) <=
            7 * 24 * 60 * 60 * 1000 ? (
              <div>
                <p style={{ fontWeight: 700 }}>다운로드 가능 기간</p>
                <p>
                  {dateFormatter(alredyPayments?.created_at, 'YY-MM-DD')} ~{' '}
                  {dateFormatter(
                    new Date(alredyPayments?.created_at).setDate(
                      new Date(alredyPayments?.created_at).getDate() + 7
                    ),
                    'YY-MM-DD'
                  )}
                </p>
                <Button
                  block
                  size="large"
                  type="primary"
                  style={{
                    marginTop: 7,
                    background: NewProduct?.num === 0 ? '#F5F5F5' : '#E3A4AF',
                    border: 'none',
                  }}
                  onClick={() => handleFileDownload(NewProduct?.productFile)}
                  disabled={NewProduct?.num === 0}
                >
                  파일 다운로드
                </Button>
                {NewProduct?.productProgram ? (
                  <Button
                    block
                    size="large"
                    type="primary"
                    style={{
                      marginTop: 7,
                      background: NewProduct?.num === 0 ? '#F5F5F5' : '#E3A4AF',
                      border: 'none',
                    }}
                    onClick={() => {
                      setProgramModal(true);
                      setProgramValue({
                        program_id: NewProduct?.productProgram?.program_id,
                        ...NewProduct?.productProgram?.content,
                      });
                    }}
                    disabled={NewProduct?.num === 0}
                  >
                    교안 다운로드
                  </Button>
                ) : null}
              </div>
            ) : (
              <Btn
                style={{ width: '100%', height: '50px' }}
                onClick={() => setIsOpened(!isOpened)}
                disabled={!(loginWho === 'user' || loginWho === 'teacher')}
              >
                구매하기
              </Btn>
            )}
          </Content>
        </BottomLayout>
      )}

      {/* 상품 정보 모달 */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'bottom'}
        width={'100%'}
        height={'50%'}
        closable
        style={{ padding: 0 }}
        footer={
          <Content maxWidth={'100%'} padding={0}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                block
                size="large"
                type="primary"
                style={{
                  marginTop: 7,
                  background: !(loginWho === 'user' || loginWho === 'teacher')
                    ? '#F5F5F5'
                    : '#E3A4AF',
                  border: 'none',
                  width: '50%',
                  marginRight: '1px',
                }}
                onClick={() => handleCreateProductCart(1)}
                disabled={!(loginWho === 'user' || loginWho === 'teacher')}
              >
                구매하기
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{
                  marginTop: 7,
                  background: !(loginWho === 'user' || loginWho === 'teacher')
                    ? '#F5F5F5'
                    : '#AB5476',
                  border: 'none',
                  width: '50%',
                  marginRight: '1px',
                }}
                onClick={() => handleCreateProductCart(2)}
                disabled={!(loginWho === 'user' || loginWho === 'teacher')}
              >
                장바구니 담기
              </Button>
            </div>
          </Content>
        }
      >
        <Content maxWidth={'100%'} padding={30}>
          <Title size={5} align="left">
            <small>
              [{' '}
              {
                productCategories
                  ?.filter(
                    (item) =>
                      item.product_category_id === NewProduct?.parents_id
                  )
                  ?.pop()?.product_category_name
              }{' '}
              ]
            </small>

            <br />
            {NewProduct?.product_name}
          </Title>
          <div>
            <Tag>{NewProduct?.product_category_name}</Tag>
            <Tag>바로 결제 가능</Tag>
          </div>
          <br />
          <div>
            <Title
              size={5}
              subButton={
                <>
                  <Title align="right">
                    {/* <small>25%</small>{' '} */}
                    {stringToMoneyFormat(NewProduct?.price, '원')}
                  </Title>
                </>
              }
            >
              판매금액
            </Title>
          </div>
        </Content>
      </ModalLayout>
      {/* 교안 미리 보기 모달 */}
      <ModalLayout
        title={'프로그램'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={programModal}
        onCancel={() => {
          setProgramModal(false);
          setProgramValue({});
        }}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 768 : '100%'}
        height={'70%'}
        closable
        zIndex={5700}
      >
        <ProgramLayout programValue={programValue} />
      </ModalLayout>
    </>
  );
};

ShopDetail.defaultProps = {};

export default ShopDetail;
