import React, { useEffect, useState } from 'react';
import MySchedulePresenter from './MySchedulePresenter';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import {
  ScheduleApi,
  CalculateApi,
  UserApi,
  AgencyApi,
  CategoryApi,
  RepetitionScheduleApi,
  ProfileApi,
} from 'api';
import { LoadingPage } from 'components';

const MyScheduleContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== PROPS ===== */
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);

  // 접속자 확인 (수강생, 강사, 기관)

  /* ===== STATE ===== */
  const [isLoading, setIsLoading] = React.useState(false);
  const uid = getCookie('USER');
  const loginWho = useRecoilValue(loginUser);
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules(
      loginWho === 'agency'
        ? {
            agency_id: parseInt(uid),
            date: `${year}-${month}`,
          }
        : {
            user_id: parseInt(uid),
            date: `${year}-${month}`,
          },
      {
        enabled: uid && year && month ? true : false,
      }
    );
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates(
      loginWho === 'agency'
        ? {
            agency_id: parseInt(uid),
            date: `${year}-${month}`,
          }
        : {
            user_id: parseInt(uid),
            date: `${year}-${month}`,
          }
    );
  const userInfo = UserApi.GetUser(uid, null, {
    enabled: !!uid && loginWho === 'teacher',
  })?.data;
  const agencyInfo = AgencyApi.GetAgency(uid, null, {
    enabled: !!uid && loginWho === 'agency',
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const agencies = AgencyApi.GetAgencies(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const users = UserApi.GetUsers(
    {
      join_table: 'teacher_file',
    },
    {
      select: (res) => {
        const { status, message, data } = res;
        if (status !== 200) {
          console.error(message);
          return [];
        }

        return data;
      },
    }
  )?.data;
  const repetitionSchedules = RepetitionScheduleApi.GetRepetitionSchedules(
    null,
    {
      select: (res) => {
        const { status, message, data } = res;
        if (status !== 200) {
          console.error(message);
          return [];
        }

        return data;
      },
    }
  )?.data;

  const profiles = ProfileApi.GetUserProfiles(null, {
    select: (res) => {
      const { status, message, data } = res;

      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const [dataState, setDataState] = useState({});

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (schedules?.status === 200 && categories) {
      // 카테고리 데이터 정제
      const m = categories?.filter((item) => item?.type === 'M');
      const d = categories?.filter((item) => item?.type === 'D');
      // 유저 데이터 정제
      const usersFilter = users?.filter((item) => item?.is_show === 'Y');
      const filterUserData = usersFilter?.map((item) => ({
        user_id: item?.user_id,
        name: item?.name,
        signature: item?.signature,
      }));
      // 기관 데이터 정제
      const agenciesFilter = agencies?.filter(
        (item) => item?.is_show === 'Y' && item?.is_enable === 'Y'
      );
      const filterAgencyData = agenciesFilter?.map((item) => ({
        agency_id: item?.agency_id,
        agency_name: item?.name,
        agency_address: item?.address,
        agency_address_detail: item?.address_detail,
        agency_type: item?.type,
        agency_phone: item?.phone,
        agency_email: item?.email,
        agency_signature: item?.signature,
      }));
      // 반복스케줄 데이터 정제
      const newRepetitionSchedules = repetitionSchedules
        ?.filter((item) => item?.user_id === parseInt(uid))
        ?.map((item) => ({
          ...item,
          ...(filterUserData &&
            filterUserData?.filter(
              (subitem) => subitem?.user_id === item?.user_id
            )[0]),
          ...(filterAgencyData &&
            filterAgencyData?.filter(
              (subitem) => subitem?.agency_id === item?.agency_id
            )[0]),
          category_name: categories?.filter(
            (subitem) => subitem?.category_id === item?.category_id
          )[0]?.category_name,
        }));
      // 스케줄 데이터 정제
      const newSchedules = schedules?.data
        ?.filter((item) =>
          loginWho === 'agency'
            ? item?.agency_id === parseInt(uid)
            : item?.user_id === parseInt(uid)
        )
        ?.map((item) =>
          loginWho === 'teacher'
            ? {
                ...item,
                ...(filterUserData &&
                  filterUserData?.filter(
                    (subitem) => subitem?.user_id === item?.user_id
                  )[0]),
                ...(filterAgencyData &&
                  filterAgencyData?.filter(
                    (subitem) => subitem?.agency_id === item?.agency_id
                  )[0]),
                category_name: categories?.filter(
                  (subitem) => subitem?.category_id === item?.category_id
                )[0]?.category_name,
              }
            : {
                ...item,
                agency_name: agencyInfo?.data?.name,
                agency_signature: agencyInfo?.data?.signature,
                ...(users &&
                  users?.filter(
                    (subitem) => subitem.user_id === item.user_id
                  )[0]),
                category_name: categories?.filter(
                  (subitem) => subitem?.category_id === item?.category_id
                )[0]?.category_name,
              }
        );

      const newProfiles = profiles?.map((profile) => {
        const { teacher_files } = users?.find(
          (user) => user?.user_id === profile?.user_id
        );

        return {
          ...profile,
          profileImage: teacher_files?.find((file) => file?.type === 'P'),
          certificateFiles: teacher_files?.filter((file) => file?.type === 'C'),
          attachmentFiles: teacher_files?.filter((file) => file?.type === 'E'),
        };
      });

      const Data = {
        myData: loginWho === 'agency' ? agencyInfo?.data : userInfo?.data,
        schedules: newSchedules,
        calculate: calculate?.data,
        major: m,
        major_detail: d,
        agencies: agenciesFilter,
        weeklyItems: newRepetitionSchedules,
        profiles: newProfiles,
      };

      setDataState(Data);
    }
  }, [
    categories,
    schedules,
    calculate,
    agencies,
    repetitionSchedules,
    profiles,
    uid,
    loginWho,
    users,
    userInfo,
    agencyInfo,
  ]);

  /**
   * 년/월에 따른 스케쥴/정산 데이터 호출
   * --
   */
  useEffect(() => {
    try {
      setIsLoading(true);

      scheduleRefetch();
      calculateRefetch();
    } catch (err) {
      console.error('refetch Error!!', err);
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [uid, year, month, scheduleRefetch, calculateRefetch]);

  /* ===== RENDER ===== */
  return (
    <>
      {isLoading ? <LoadingPage text={'데이터를 불러오는중입니다'} /> : null}
      <MySchedulePresenter
        myData={dataState?.myData}
        loginWho={loginWho}
        year={year}
        setYear={setYear}
        month={month}
        setMonth={setMonth}
        schedules={dataState.schedules}
        calculate={dataState.calculate}
        major={dataState.major}
        majorDetail={dataState.major_detail}
        weeklyItems={dataState.weeklyItems}
        agencies={dataState?.agencies}
        profiles={dataState?.profiles}
      />
    </>
  );
};

MyScheduleContainer.defaultProps = {};

export default MyScheduleContainer;
