import React from 'react';
import MyLearningPresenter from './MyLearningPresenter';
import { getCookie } from 'utils';
import {
  AnswerExamApi,
  CategoryApi,
  ChapterApi,
  DifficultyApi,
  ExamApi,
  LectureApi,
  LectureOfUserApi,
  SectionApi,
  UserApi,
} from 'api';

const MyLearningContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureOfUsers = LectureOfUserApi.GetLectureOfUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const chapters = ChapterApi.GetChapters(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const sections = SectionApi.GetSections(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const exams = ExamApi.GetExams(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const answerExams = AnswerExamApi.GetAnswerExams(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const difficulties = DifficultyApi.GetDifficulties(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
    cacheTime: 'Infinity',
  })?.data;
  const idx = parseInt(getCookie('USER'));
  // console.log('lectureOfUsers', lectureOfUsers);

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <MyLearningPresenter
      lectures={lectures}
      users={users}
      lectureOfUsers={lectureOfUsers}
      chapters={chapters}
      sections={sections}
      categories={categories}
      exams={exams}
      answerExams={answerExams}
      difficulties={difficulties}
      idx={idx}
    />
  );
};

MyLearningContainer.defaultProps = {};

export default MyLearningContainer;
