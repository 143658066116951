/**
 * CourseStatusContainer
 * --
 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CourseStatusPresenter from './CourseStatusPresenter';
import { decrypt, encrypt } from 'utils/Crypto';
import { NotFoundPage } from 'components';
import {
  BoardApi,
  LectureOfUserApi,
  EmailApi,
  UserApi,
  LectureApi,
  ChapterApi,
  SectionApi,
  FileApi,
} from 'api';
import { DOMAIN_URL, getCookie } from 'utils';
import MessageAlert from 'utils/MessageAlert';

const CourseStatusContainer = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const page = searchParams.get('page');

  /* ===== DATA ===== */
  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const chapters = ChapterApi.GetChapters(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const sections = SectionApi.GetSections(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const teacherFiles = FileApi.GetTeacherFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const { data: board, refetch: boardRefetch } = BoardApi.GetBoards(
    {
      lecture_id: decrypt(lectureId),
    },
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );
  const { data: lectureOfUsers, refetch: lectureOfUsersRefetch } =
    LectureOfUserApi.GetLectureOfUsers(
      {
        lecture_id: decrypt(lectureId),
      },
      {
        enabled: !!lectureId,
        select: (data) => data?.data,
      }
    );

  /* ===== PROPS ===== */
  /* ===== STATE ===== */
  const [data, setData] = useState({});
  // 선택 강의
  const [selectedLectureId, setSelectedLectureId] = useState(null);

  const filterOptions = [
    {
      label: '전체',
      value: 'ALL',
    },
    {
      label: '문의 게시판',
      value: 'INQUIRY',
    },
    {
      label: '토론 게시판',
      value: 'DISCUSSION',
    },
    {
      label: '과제 게시판',
      value: 'ASSIGNMENT',
    },
  ];
  const [filterValue, setFilterValue] = useState(filterOptions[0].value);
  // 페이지
  const [currentPage, setCurrentPage] = useState(1);
  // 선택 강의 정보
  const [selectedLecture, setSelectedLecture] = useState({});

  /* ===== VARIABLE ===== */
  /* ===== FUNCTION ===== */
  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigate(
      `/course?lecture=${encodeURIComponent(
        encrypt(selectedLectureId)
      )}&page=${page}`
    );
  };

  /**
   * 강의 선택
   * --
   */
  const handleSelectLecture = (id) => {
    navigate(`/course?lecture=${encodeURIComponent(encrypt(id))}&page=1`);
  };

  /**
   * 게시판 상세 팝업
   * --
   */
  const handleDetailPopup = (e, d) => {
    e.stopPropagation();

    const domain = DOMAIN_URL;

    window.open(
      `${domain}/#/popup/board/detail/${encodeURIComponent(
        encrypt(d?.board_id)
      )}?lecture=${encodeURIComponent(
        encrypt(d?.lecture_id)
      )}&type=${d?.type?.toLocaleLowerCase()}`,
      '팝업',
      'width=1500, height=850'
    );
  };

  /**
   * 독려 메세지 전송
   * --
   */
  const handleSendMessage = async (type, data) => {
    const { name, user_id, email, phone } = data;

    const confirm = (title) =>
      window.confirm(`강의 독려 ${title}를 전송하시겠습니까?`);

    switch (type) {
      case 'EMAIL':
      case 'Email':
      case 'email':
        if (confirm('이메일')) {
          const { status } = await EmailApi.Sendmail({
            toEmail: email,
            type: 'encouragement',
            template_value: {
              lecture_title: selectedLecture?.title,
              name: name,
              user_id: user_id,
            },
          });

          if (status !== 200)
            return MessageAlert.error('이메일을 전송하는데 실패하였습니다!');

          return MessageAlert.success('성공적으로 이메일을 전송하였습니다.');
        } else {
          return;
        }
      case 'SMS':
      case 'Sms':
      case 'sms':
        // TODO: 독려 문자 전송
        if (confirm('문자')) {
          const { status } = await EmailApi.Sendsms({
            type: 'send',
            receiver: phone,
            msg: `${name}님 ${selectedLecture?.title} 강의 진도율이 낮습니다.조금만 더 힘내세요!`,
          });
          if (status !== 200)
            return MessageAlert.error('문자를 전송하는데 실패하였습니다!');

          return MessageAlert.success('성공적으로 문자를 전송하였습니다.');
        } else {
          return;
        }
      default:
        break;
    }
  };

  /* ===== HOOKS ===== */
  /**
   * 데이터 Refetch
   * --
   */
  useEffect(() => {
    if (lectureId) {
      boardRefetch();
      lectureOfUsersRefetch();
    }
  }, [lectureId, boardRefetch, lectureOfUsersRefetch]);

  /**
   * 데이터 정제
   * --
   */
  useEffect(() => {
    // 수강생 정보 정제
    const student = lectureOfUsers?.map((item) => ({
      ...(users &&
        users?.filter((subitem) => item?.user_id === subitem?.user_id)[0]),
      ...item,
      profile: teacherFiles
        ?.filter(
          (subitem) =>
            item?.user_id === subitem?.user_id && subitem?.type === 'P'
        )
        ?.pop(),
      lecture_start_date:
        item?.progress?.length && item?.progress[0]?.created_at,
      lecture_last_date:
        item?.progress?.length &&
        item?.progress[item?.progress.length - 1]?.created_at,
      payment_date: item?.created_at,
    }));

    // 게시판 파일 정제
    const files = board?.reduce((acc, cur) => {
      let arr = acc;
      if (cur?.board_file?.length) {
        acc = [...arr, ...cur?.board_file];
        return acc;
      } else {
        acc = arr;
        return acc;
      }
    }, []);

    const newBoardData = {
      board: board?.map((item) => {
        const { comment, ...d } = item;

        return {
          ...d,
          ...student?.find((user) => item?.user_id === user?.user_id),
          writer:
            item?.user_id === 0
              ? '관리자'
              : student?.find((user) => item?.user_id === user?.user_id)?.name,
          board_created_at: item?.created_at,
          board_update_at: item?.updated_at,
          comment: comment?.map((subitem) => ({
            ...subitem,
            writer: lectureOfUsers?.find(
              (user) => subitem?.user_id === user?.user_id
            ),
          })),
        };
      }),
      board_files: files,
    };

    // 내 강의 정보 정제
    const myLectuers = lectures
      ?.filter((item) => item.user_id === parseInt(getCookie('USER')))
      ?.map((item) => ({
        ...item,
        chapters: chapters?.filter(
          (subitem) => subitem?.lecture_id === item?.lecture_id
        ),
        sections: sections?.reduce((acc, cur) => {
          const c = chapters?.filter(
            (subitem) => subitem?.lecture_id === item?.lecture_id
          );

          const isExist = c?.some(
            (subitem) => subitem?.chapter_id === cur?.chapter_id
          );
          if (isExist) {
            acc.push(cur);
            return acc;
          } else {
            return acc;
          }
        }, []),
      }));

    const newData = {
      ...newBoardData,
      lectures: myLectuers?.map((item) => ({
        ...item,
        student: student
          ?.filter((subitem) => item?.lecture_id === subitem?.lecture_id)
          ?.map((subitem) => ({
            ...subitem,
            progress_status: subitem?.progress?.length
              ? (subitem?.progress?.filter((item) => item?.status === 'DONE')
                  .length /
                  item?.sections?.length) *
                100
              : 0,
          })),
      })),
      students: student,
    };

    setData(newData);
  }, [
    board,
    users,
    lectures,
    lectureOfUsers,
    teacherFiles,
    sections,
    chapters,
  ]);

  /**
   * 선택 강의 id
   * --
   */
  useEffect(() => {
    setSelectedLectureId(decrypt(lectureId));

    const l = data?.lectures?.find(
      (item) => item?.lecture_id === parseInt(decrypt(lectureId))
    );
    setSelectedLecture(l);
  }, [lectureId, data.lectures]);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  /* ===== RENDER ===== */
  return (
    <>
      {decrypt(lectureId) ? (
        <CourseStatusPresenter
          data={data}
          currentPage={currentPage}
          onPagination={handlePagination}
          filterOptions={filterOptions}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          onSelect={handleSelectLecture}
          lectureId={lectureId}
          onDetailPopup={handleDetailPopup}
          lecture={selectedLecture}
          onSendMessage={handleSendMessage}
        />
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default CourseStatusContainer;
