import React, { useState, useEffect, useMemo } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Button,
  ModalLayout,
  Calendar,
  FileUploadButton,
} from 'components';
import {
  getCookie,
  timeInputFormatter,
  handleDeleteCheck,
  handleDiffTime,
  STORE_URL,
  DOMAIN_URL,
} from 'utils';
import {
  Input,
  Select,
  Radio,
  Button as Btn,
  Badge,
  Empty,
  List,
  Card,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment-timezone';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import 'styles/antdCollapse.css';
import { FileUploadManager } from 'utils/FileUploadManager';
import {
  AgencyApi,
  FileApi,
  RepetitionScheduleApi,
  ScheduleApi,
  UserApi,
} from 'api';
import MessageAlert from 'utils/MessageAlert';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { encrypt } from 'utils/Crypto';

dayjs.extend(customParseFormat);

const weekItems = [
  {
    label: <p style={{ color: 'red' }}>일요일</p>,
    mobile_label: <p style={{ color: 'red' }}>일</p>,
    value: 'SUN',
  },
  {
    label: <p>월요일</p>,
    mobile_label: <p>월</p>,
    value: 'MON',
  },
  {
    label: <p>화요일</p>,
    mobile_label: <p>화</p>,
    value: 'TUE',
  },
  {
    label: <p>수요일</p>,
    mobile_label: <p>수</p>,
    value: 'WED',
  },
  {
    label: <p>목요일</p>,
    mobile_label: <p>목</p>,
    value: 'THU',
  },
  {
    label: <p>금요일</p>,
    mobile_label: <p>금</p>,
    value: 'FRI',
  },
  {
    label: <p style={{ color: 'blue' }}>토요일</p>,
    mobile_label: <p style={{ color: 'blue' }}>토</p>,
    value: 'SAT',
  },
];

const MySchedulePresenter = (props) => {
  /* ===== Props ===== */
  const {
    myData,
    schedules,
    calculate,
    major,
    majorDetail,
    weeklyItems,
    agencies,
    profiles,
    year,
    setYear,
    month,
    setMonth,
    loginWho,
  } = props;
  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Mutate ===== */
  const queryClient = useQueryClient();
  const createSchedule = ScheduleApi.CreateSchedule({
    onSuccess: async (data, variables) => {
      if (variables?.file) {
        const res = await FileUploadManager('S', variables?.file);

        if (res.status === 200) {
          createScheduleFile.mutate({
            schedule_id: data?.data?.schedule_id,
            file_name: res.file_name,
            file_url: res.name,
            type: res.type,
          });
        }
      }
    },
  });
  const createScheduleFile = FileApi.CreateScheduleFile();
  const createRepetitionSchedule =
    RepetitionScheduleApi.CreateRepetitionSchedule();
  const updateRepetitionSchedule =
    RepetitionScheduleApi.UpdateRepetitionSchedule();
  const deleteRepetitionSchedule =
    RepetitionScheduleApi.DeleteRepetitionSchedule();
  const updateUser = UserApi.UpdateUser();
  const updateAgency = AgencyApi.UpdateAgency();

  /* ===== State ===== */
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const [events, setEvents] = useState([]);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [week, setWeek] = useState(null);
  const [day, setDay] = useState(1);
  const [weeklyItemList, setWeeklyItemList] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [isPlanetOpened, setIsPlanetOpened] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);

  const [modalType, setModalType] = useState(1);
  const [programValue, setProgramValue] = useState({});
  // console.log('programValue', programValue);
  // 기관 Select 아이템
  const [agencyList, setAgencyList] = useState([]);
  const [treeData, setTreeData] = useState([]);
  // 주별/월별 일정 생성 모달
  const [programData, setProgramData] = useState({
    type: 1,
    user_id: null,
    agency_id: null,
    category_id: null,
    content: null,
    week: null,
    weekday: 'MON',
    start_time: null,
    end_time: null,
    schedule_type: 'S',
    registrant: 'teacher',
  });
  // 일광등록 아이템
  const [repetitionEvents, setRepetitionEvents] = useState([]);
  const [scheduleItems, setScheduleItems] = useState([]);
  // 서명 모달
  const [signModal, setSignModal] = useState(false);
  // 서명 파일
  const [signFile, setSignFile] = useState(null);
  // 화면 표시를 위한 임시 url
  const [createObjectURL, setCreateObjectURL] = useState(null);
  // 강의 일지 모달
  const [journalModal, setJournalModal] = useState(false);
  const [journalData, setJournalData] = useState([]);

  // 캘린더 아이템
  const items = useMemo(() => {
    const s = schedules?.map((item) => ({
      id: item?.schedule_id,
      title:
        loginWho === 'teacher'
          ? `${item?.category_name} (${moment(item?.start_date).format(
              'HH:mm'
            )} ~ ${moment(item?.end_date).format('HH:mm')})`
          : loginWho === 'agency' && `${item?.category_name} (${item?.name})`,
      start: new Date(item?.start_date),
      end: new Date(item?.end_date),
      user_confirm: item?.user_confirm,
      agency_confirm: item?.agency_confirm,
      type: item?.schedule_type,
      content: item?.content,
      name:
        loginWho === 'teacher'
          ? `${item?.agency_name} (${item?.agency_type})`
          : loginWho === 'agency' && `${item?.name}`,
      email: item?.email,
      phone: item?.phone,
      file: item?.file,
      chief_name: item?.chief_name,
      address: loginWho === 'teacher' ? item?.agency_address : item?.address,
      address_detail:
        loginWho === 'teacher'
          ? item?.agency_address_detail
          : item?.address_detail,
      agency_id: item?.agency_id,
      registrant: item?.registrant,
      user_id: item?.user_id,
      category_id: item?.category_id,
      category_name: item?.category_name,
      user_check: item?.user_check,
      agency_check: item?.agency_check,
      profile: profiles
        ?.filter((subitem) => item?.user_id === subitem?.user_id)
        ?.pop(),
      is_calculate: handleDeleteCheck(item, calculate),
      repetition_schedule_id: item?.repetition_schedule_id,
    }));
    return s;
  }, [schedules, loginWho, profiles, calculate]);

  /* ===== Hooks ===== */
  /**
   * 스케쥴 이벤트 아이템 정제
   * --
   */
  useEffect(() => {
    setEvents(items);
  }, [items]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 일반회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'user') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 반복스케쥴 값 초기화
   * --
   */
  useEffect(() => {
    if (modalType === 1) {
      setWeek(null);
    } else {
      setWeek(0);
    }
  }, [modalType]);

  /**
   * 반복 스케쥴 정제
   * --
   */
  useEffect(() => {
    let newItems;
    if (modalType === 1) {
      newItems = weekItems?.map((item) => ({
        ...item,
        items: weeklyItems?.filter(
          (subitem) =>
            subitem?.weekday === item?.value && subitem?.week === null
        ),
      }));
    } else {
      const list = [1, 2, 3, 4];
      newItems = list?.map((item, index) => [
        ...weekItems?.map((subitem) => ({
          ...subitem,
          items: weeklyItems?.filter(
            (thirditem) =>
              thirditem?.weekday === subitem?.value && thirditem?.week === index
          ),
        })),
      ]);
    }
    setWeeklyItemList(newItems);
  }, [weeklyItems, week, day, modalType]);

  /**
   * 기관 Select 아이템 정제
   * --
   */
  useEffect(() => {
    const newItem = agencies?.map((item) => ({
      label: (
        <div>
          <h4 style={{ fontWeight: 700 }}>
            {item?.name}{' '}
            <span style={{ fontSize: 12, fontWeight: 400 }}>
              ({item?.type})
            </span>
          </h4>
          <p style={{ color: '#BDBDBD' }}>{`${item?.address} ${
            item?.address_detail ? item?.address_detail : ''
          }`}</p>
        </div>
      ),
      value: item?.agency_id,
    }));
    setAgencyList(newItem);
  }, [agencies]);

  /**
   * 교안등록모달 Select 아이템 정제
   * --
   */
  useEffect(() => {
    if (major && majorDetail) {
      const items = majorDetail?.map((item) => ({
        label: <p style={{ fontSize: 16 }}>{item?.category_name}</p>,
        value: item?.category_id,
      }));
      setTreeData(items);
    }
  }, [major, majorDetail]);

  /**
   * 반복 스케쥴 등록 모달 값 초기화
   * --
   */
  useEffect(() => {
    if (!scheduleModal) {
      setProgramData({
        type: 1,
        user_id: null,
        agency_id: null,
        category_id: null,
        content: null,
        week: null,
        weekday: 'MON',
        start_time: null,
        end_time: null,
        schedule_type: 'S',
        registrant: 'teacher',
      });
    }
  }, [scheduleModal]);

  /**
   * 일정일괄등록 모달 값 초기화
   * --
   */
  useEffect(() => {
    if (!isOpened) {
      setScheduleItems([]);
      setRepetitionEvents([]);
    }
  }, [isOpened]);

  /**
   * 서명 모달 값 초기화
   * --
   */
  useEffect(() => {
    if (!signModal) {
      URL.revokeObjectURL(createObjectURL);
      setSignFile(null);
      setCreateObjectURL(null);
    }
  }, [signModal, createObjectURL]);

  /**
   * 강의 일지 데이터 정제
   * --
   */
  useEffect(() => {
    let arr = [];
    schedules?.forEach((item, index) => {
      if (!item?.repetition_schedule_id) {
        arr.push({ key: `null-${index}`, data: [item] });
      } else {
        const ext = arr.findIndex(
          (subitem) => subitem?.key === `${item?.repetition_schedule_id}`
        );
        if (ext === -1) {
          arr.push({ key: `${item?.repetition_schedule_id}`, data: [item] });
        } else {
          const newData = [...arr[ext]?.data, item];
          arr[ext].data = newData;
        }
      }
    });
    setJournalData(arr);
  }, [schedules]);

  /* ===== Functions ===== */
  /**
   * 스케쥴 관리 모달
   * --
   * NOTE: 1: 주/월별 일정관리 | 2: 일정 일괄등록
   */
  const handleSchedule = (type) => {
    if (type === 1) {
      setIsPlanetOpened(!isPlanetOpened);
    } else {
      setIsOpened(!isOpened);
    }
  };

  /**
   * 프로그램 데이터 바인딩 함수
   * --
   */
  const handleProgramChage = (t, v) => {
    let newData = { ...programData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setProgramData(d);
  };

  /**
   * 반복 스케쥴 등록/수정/삭제
   * --
   */
  const handleRepeatSchedule = (type, data) => {
    const validateCheck = () => {
      if (
        programData?.type === 2 &&
        (programData?.week === null || programData?.week === undefined)
      ) {
        MessageAlert.warning('반복할 주를 선택해주세요!');
        return false;
      }
      if (!programData?.weekday) {
        MessageAlert.warning('반복할 요일을 선택해주세요!');
        return false;
      }
      if (!programData?.start_time) {
        MessageAlert.warning('시작시간을 입력해주세요!');
        return false;
      }
      if (!programData?.end_time) {
        MessageAlert.warning('종료시간을 입력해주세요!');
        return false;
      }
      const reg = /^([0-9]{1,2}):([0-5][0-9])$/;
      if (
        !reg.test(programData?.start_time) ||
        !reg.test(programData?.end_time)
      ) {
        MessageAlert.warning('HH:mm 형태로 시간을 입력해주세요');
        return;
      }
      if (
        programData?.start_time > '24:00' ||
        programData?.end_time > '24:00'
      ) {
        MessageAlert.warning('24:00시 이후로는 입력이 불가능합니다!');
        return;
      }
      if (programData?.start_time >= programData?.end_time) {
        MessageAlert.warning('종료시간은 시작시간 이후로만 입력가능합니다!');
        return false;
      }
      // 두 시간 차이 계산
      const diffTime = handleDiffTime(
        programData?.start_time,
        programData?.end_time,
        'h'
      );
      if (diffTime < 1) {
        MessageAlert.warning('1시간 미만의 스케줄은 등록할 수 없습니다.');
        return;
      }
      if (!programData?.agency_id) {
        MessageAlert.warning('기관을 선택해주세요!');
        return false;
      }
      if (!programData?.category_id) {
        MessageAlert.warning('프로그램을 선택해주세요!');
        return false;
      }

      return true;
    };

    let confirm;
    switch (type) {
      case 'C':
      case 'c':
        if (!validateCheck()) return;

        confirm = window.confirm('반복 일정을 등록하시겠습니까?');
        if (confirm) {
          try {
            createRepetitionSchedule.mutate({
              ...programData,
              user_id: uid,
              start_time: programData?.start_time,
              end_time: programData?.end_time,
              content: {
                ...programValue,
              },
            });
            MessageAlert.success('성공적으로 등록되었습니다');
          } catch (err) {
            console.log('반복일정 등록 실패!', err);
            MessageAlert.error('반복일정을 등록하는데 실패하였습니다!');
            throw err;
          }
        }

        break;
      case 'U':
      case 'u':
        if (!validateCheck()) return;

        confirm = window.confirm('반복 일정을 수정하시겠습니까?');
        if (confirm) {
          try {
            const { created_at, ...d } = data;
            updateRepetitionSchedule.mutate({
              ...d,
              start_time: programData?.start_time,
              end_time: programData?.end_time,
              content: {
                ...programValue,
              },
            });
          } catch (err) {
            console.log('반복일정 수정 실패!', err);
            MessageAlert.error('반복일정을 수정하는데 실패하였습니다!');
            throw err;
          }
        }

        break;
      case 'D':
      case 'd':
        confirm = window.confirm('일정을 정말 삭제하시겠습니까?');
        if (confirm) {
          try {
            const { repetition_schedule_id } = data;
            deleteRepetitionSchedule.mutate({
              repetition_schedule_id: repetition_schedule_id,
            });
            MessageAlert.success('삭제되었습니다');
          } catch (err) {
            console.log('반복일정 삭제 실패!', err);
            MessageAlert.error('반복일정을 삭제하는데 실패하였습니다!');
            throw err;
          }
        }
        break;
      default:
        break;
    }
    setScheduleModal(false);
    setProgramValue({});
    setProgramData({
      type: 1,
      user_id: null,
      agency_id: null,
      category_id: null,
      content: null,
      week: null,
      weekday: 'MON',
      start_time: null,
      end_time: null,
      schedule_type: 'S',
      registrant: 'teacher',
    });
  };

  /**
   * 반복일정 날짜 계산
   * --
   */
  const monthSchedule = ScheduleApi.GetSchedules(
    {
      date: `${year}-${month}`,
    },
    {
      enabled: year && month ? true : false,
    }
  )?.data?.data;
  const handleRepetitionSchedule = () => {
    /**
     * 해당 월에 날짜 계산하는 함수
     * --
     */
    const calculateDate = (data) => {
      let newScheduleData = [];
      data?.forEach((item) => {
        const {
          user_id,
          agency_id,
          category_id,
          content,
          start_time,
          end_time,
          agency_name,
          category_name,
          repetition_schedule_id,
          agency_type,
          agency_address,
          agency_address_detail,
        } = item;

        const today = new Date(`${year} ${month}`);
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        const weekdayIndex = weekItems?.findIndex(
          (subitem) => subitem?.value === item?.weekday
        );

        if (item?.week !== null) {
          // 월 반복일 경우
          const firstDayOfWeek = firstDayOfMonth.getDay();
          let difference = weekdayIndex - firstDayOfWeek;

          if (difference < 0) {
            difference += 7;
          }
          const targetDate = new Date(firstDayOfMonth);
          targetDate.setDate(1 + difference + item.week * 7);

          newScheduleData.push({
            user_id: user_id,
            agency_id: agency_id,
            category_id: category_id,
            content: { ...content },
            start_date: `${moment(targetDate).format(
              'YYYY-MM-DD'
            )} ${start_time}`,
            end_date: `${moment(targetDate).format('YYYY-MM-DD')} ${end_time}`,
            agency_name: agency_name,
            category_name: category_name,
            repetition_schedule_id: repetition_schedule_id,
            agency_type: agency_type,
            agency_address: agency_address,
            agency_address_detail: agency_address_detail,
          });
        } else {
          // 주 반복일 경우
          let weekDates = [];

          for (
            let d = firstDayOfMonth;
            d <= lastDayOfMonth;
            d.setDate(d.getDate() + 1)
          ) {
            if (d.getDay() === weekdayIndex) {
              weekDates.push(new Date(d));
            }
          }

          weekDates.forEach((date) => {
            newScheduleData.push({
              user_id: user_id,
              agency_id: agency_id,
              category_id: category_id,
              content: { ...content?.content },
              start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
              end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
              agency_name: agency_name,
              category_name: category_name,
              repetition_schedule_id: repetition_schedule_id,
              agency_type: agency_type,
              agency_address: agency_address,
              agency_address_detail: agency_address_detail,
            });
          });
        }
      });

      const exist = [],
        noExist = [];

      newScheduleData?.forEach((item) => {
        const findItem = monthSchedule?.find(
          (subitem) =>
            subitem?.repetition_schedule_id === item?.repetition_schedule_id
        );

        if (findItem) {
          exist.push({
            ...item,
            exist: true,
          });
        } else {
          noExist.push({
            ...item,
            exist: false,
          });
        }
      });
      const eventList = [...exist, ...noExist];
      const events = eventList?.map((item, index) => ({
        id: index,
        title: (
          <p>
            {item?.category_name} ({moment(item?.start_date).format('HH:mm')} ~{' '}
            {moment(item?.end_date).format('HH:mm')})<br />
            <span>{item?.agency_name}</span>
          </p>
        ),
        start: new Date(item?.start_date),
        end: new Date(item?.end_date),
        type: item?.exist ? 'S' : 'N',
        category_name: item?.category_name,
        name: `${item?.agency_name} (${item?.agency_type})`,
        address: item?.agency_address,
        address_detail: item?.agency_address_detail,
      }));
      setRepetitionEvents(events);
      setScheduleItems(noExist);
      setIsOpened(!isOpened);
    };
    calculateDate(weeklyItems);
  };

  /**
   * 일정 일괄등록
   * --
   */
  const handleBulkSchedule = () => {
    if (scheduleItems?.length) {
      const confirm = window.confirm('일정을 일괄 등록하시겠습니까?');
      if (confirm) {
        try {
          createSchedule.mutate(scheduleItems);
          MessageAlert.success('일정이 일괄등록되었습니다!');
          setIsOpened(false);
        } catch (err) {
          MessageAlert.error('일정 일괄등록에 실패하였습니다!');
          console.log('일정일괄등록실패!', err);
          throw err;
        }
      }
    } else {
      MessageAlert.warning('등록할 스케쥴이 없습니다!');
      return;
    }
  };

  /**
   * 팝업 메시지
   * --
   */
  const handlePopupMessage = (event) => {
    if (event.data === 'refresh') {
      queryClient.invalidateQueries('getSchedules');
    }
  };

  /**
   * 옵션필터 검색
   * --
   * @param {*} value
   * @param {*} label
   */
  const handleFilterOption = (value, label) => {
    const agencyName = label.props?.children[0]?.props?.children[0];
    return agencyName?.toLowerCase()?.includes(value?.toLowerCase());
  };

  /**
   * 서명 이미지 추가 함수
   * --
   * @param {*} file
   */
  const handleSignImage = (file) => {
    if (createObjectURL) {
      URL.revokeObjectURL(createObjectURL);
    }

    if (file) {
      setSignFile(file);
      setCreateObjectURL(URL.createObjectURL(file));
    }
  };

  /**
   * 서명파일 삭제 핸들러
   * --
   */
  const handleDeleteSignFile = () => {
    // 등록된 서명 파일이 있는 경우
    if (myData?.signature) {
      const confirm = window.confirm(
        '정말 등록된 서명 파일을 삭제하시겠습니까?\n삭제 후 복구할 수 없습니다.'
      );
      if (confirm) {
        if (loginWho === 'agency') {
          updateAgency.mutate({
            agency_id: uid,
            signature: null,
          });
        } else {
          updateUser.mutate({
            user_id: uid,
            signature: null,
          });
        }
        MessageAlert.success('등록된 서명파일이 성공적으로 삭제되었습니다.');
      } else {
        MessageAlert.warning('취소되었습니다.');
        return;
      }
    }
    // 등록된 서명파일이 없는 경우
    else {
      setSignFile(null);
      URL.revokeObjectURL(createObjectURL);
      setCreateObjectURL(null);
    }
  };

  /**
   * 서명파일 등록 핸들러
   * --
   */
  const handleUploadFile = async () => {
    const confirm = window.confirm('서명파일을 등록하시겠습니까?');

    if (confirm) {
      try {
        const path = loginWho === 'agency' ? `agency/${uid}/` : `users/${uid}/`;
        const res = await FileUploadManager('SIGN', signFile, path);
        if (loginWho === 'agency') {
          updateAgency.mutate({
            agency_id: uid,
            signature: res.name,
          });
        } else {
          updateUser.mutate({
            user_id: uid,
            signature: res.name,
          });
        }
        MessageAlert.success('등록된 서명파일이 성공적으로 등록되었습니다.');
      } catch (err) {
        MessageAlert.error('서명파일을 등록하는데 오류가 발생하였습니다.');
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다.');
      return;
    }
  };

  /**
   * 강의 일지 팝업 핸들러
   * --
   */
  const handlePopup = (data) => {
    const popData = data[0];
    const domain = DOMAIN_URL;
    const popupData = {
      program: popData?.category_name,
      agency_name: popData?.agency_name,
      teacher_name: popData?.name,
      address: popData?.address,
      year: year,
      month: month,
      start_time: moment(popData?.start_date).format('HH:mm'),
      end_time: moment(popData?.end_date).format('HH:mm'),
      schedules: [...data],
    };
    const encodeData = encrypt(JSON.stringify(popupData));
    window.open(
      `${domain}/#/popup/journal?data=${encodeURIComponent(encodeData)}`,
      '팝업',
      'width=1200,height=800'
    );
  };

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={'100%'} padding={'0 12px'}>
        <Row>
          <Col x={24} padding={8}>
            <div
              style={{
                marginBottom: '28px',
                padding: 8,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title
                size={screenSize >= 768 ? 2 : 4}
                padding={8}
                style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
              >
                내일정
              </Title>
              <Btn
                icon={<UploadOutlined />}
                onClick={() => setSignModal(!signModal)}
              >
                서명파일
              </Btn>
            </div>
            <Content
              maxWidth={'100%'}
              padding={8}
              height={screenSize >= 768 ? 850 : 500}
              style={{ marginTop: '30px' }}
            >
              <Calendar
                items={events}
                height={'100%'}
                add={true}
                detail={true}
                update={true}
                teacherId={
                  loginWho === 'teacher' ? parseInt(getCookie('USER')) : null
                }
                setYear={setYear}
                setMonth={setMonth}
                confirm={true}
                onPopupMessage={handlePopupMessage}
                profile={loginWho === 'agency' ? true : false}
                program
                agencyView={loginWho === 'agency' ? true : false}
              />
            </Content>
            {loginWho === 'agency' ? (
              <Content
                maxWidth={'100%'}
                padding={8}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  layoutStyle={{ margin: 0 }}
                  onClick={() => setJournalModal(!journalModal)}
                  style={{ fontSize: screenSize >= 768 ? 14 : 12 }}
                >
                  {month}월 강의 일지
                </Button>
              </Content>
            ) : (
              <Content
                maxWidth={'100%'}
                padding={8}
                style={{
                  display: 'flex',
                  alignItems: screenSize >= 768 ? 'center' : 'flex-end',
                  justifyContent: 'space-between',
                  flexDirection: screenSize >= 768 ? 'row' : 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: screenSize >= 768 ? 0 : 20,
                  }}
                >
                  <Button
                    layoutStyle={{ margin: 0 }}
                    onClick={() => setJournalModal(!journalModal)}
                    style={{ fontSize: screenSize >= 768 ? 14 : 12 }}
                  >
                    {month}월 강의 일지
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:
                      screenSize >= 768 ? 'flex-end' : 'space-between',
                  }}
                >
                  <Button
                    layoutStyle={{
                      margin: 0,
                      marginRight: screenSize >= 768 ? 15 : 5,
                    }}
                    color="secondary"
                    onClick={() => handleSchedule(1)}
                    style={{ fontSize: screenSize >= 768 ? 14 : 12 }}
                  >
                    주별/월별 일정 관리
                  </Button>
                  <Button
                    layoutStyle={{ margin: 0 }}
                    onClick={
                      // () => handleSchedule(2)
                      () => handleRepetitionSchedule()
                    }
                    style={{ fontSize: screenSize >= 768 ? 14 : 12 }}
                  >
                    {month}월 일정 일괄등록
                  </Button>
                </div>
              </Content>
            )}
          </Col>
        </Row>
      </Content>

      {/* 주별/월별 일정관리 모달 */}
      <ModalLayout
        title={'주별/월별 일정관리'}
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={isPlanetOpened}
        onCancel={setIsPlanetOpened}
        placement={screenSize >= 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 700 : '100%'}
        height={'80%'}
        closable
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
        footer={
          <Button
            style={{ width: '100%', height: 56, fontSize: 20 }}
            onClick={() => setScheduleModal(!scheduleModal)}
          >
            일정추가
          </Button>
        }
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Content maxWidth={'100%'}>
                <Radio.Group
                  defaultValue={1}
                  size="large"
                  onChange={(e) => {
                    setModalType(e.target.value);
                    handleProgramChage('type', e.target.value);
                  }}
                >
                  <Radio.Button value={1}>주별 프로그램</Radio.Button>
                  <Radio.Button value={2}>월별 프로그램</Radio.Button>
                </Radio.Group>
              </Content>
              {modalType === 1 ? (
                // 주별 프로그램
                <Content maxWidth={'100%'}>
                  <Row>
                    <Col x={24}>
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {weeklyItemList?.map((item, index) => (
                          <li
                            key={item?.value}
                            style={{
                              width: 'calc(100% / 7)',
                              border:
                                day === index
                                  ? '1px solid #40A9FF'
                                  : '1px solid #E0E0E0',
                              textAlign: 'center',
                              padding: '10px 0',
                              cursor: 'pointer',
                              color: day === index && '#40A9FF',
                            }}
                            onClick={() => {
                              setDay(index);
                              handleProgramChage('weekday', item?.value);
                            }}
                          >
                            <Badge
                              count={item?.items?.length}
                              offset={screenSize >= 768 ? [15, 0] : [10, 0]}
                            >
                              {screenSize >= 768
                                ? item?.label
                                : item?.mobile_label}
                            </Badge>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col x={24} style={{ height: 500 }}>
                      {weeklyItemList[day] &&
                      weeklyItemList[day]?.items?.length ? (
                        <List
                          grid={
                            screenSize >= 768
                              ? { gutter: 12, column: 2 }
                              : { gutter: 0, column: 1 }
                          }
                          dataSource={weeklyItemList[day]?.items}
                          renderItem={(item, index) => (
                            <List.Item key={`item-${index}`}>
                              <Card
                                title={
                                  <p>
                                    {item?.category_name}{' '}
                                    <span
                                      style={{ fontSize: 14, color: '#BDBDBD' }}
                                    >
                                      (
                                      {`${item?.start_time} ~ ${item?.end_time}`}
                                      )
                                    </span>
                                  </p>
                                }
                                hoverable
                                extra={
                                  <Btn
                                    type="text"
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      handleRepeatSchedule('D', item);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <DeleteOutlined
                                      style={{ color: '#FB4E4E' }}
                                    />
                                  </Btn>
                                }
                                onClick={() => {
                                  setProgramData({
                                    ...item,
                                    start_time: item?.start_time,
                                    end_time: item?.end_time,
                                    type: item?.week ? 2 : 1,
                                  });
                                  setScheduleModal(!scheduleModal);
                                  setProgramValue(item?.content);
                                }}
                              >
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {item?.agency_name}{' '}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: '#BDBDBD',
                                    }}
                                  >
                                    ({item?.agency_type})
                                  </span>
                                </p>
                                <p>{item?.agency_address}</p>
                                <p>
                                  {item?.agency_address_detail
                                    ? item?.agency_address_detail
                                    : ''}
                                </p>
                              </Card>
                            </List.Item>
                          )}
                        />
                      ) : (
                        <Row
                          style={{
                            height: '100%',
                          }}
                        >
                          <Col x={24}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              style={{
                                height: '100%',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Content>
              ) : (
                // 월별 프로그램
                <Content maxWidth={'100%'}>
                  <Row>
                    <Col x={24}>
                      <Select
                        size="large"
                        style={{ width: '100%' }}
                        value={week}
                        options={[1, 2, 3, 4]?.map((item, index) => ({
                          label: `${item}주`,
                          value: index,
                        }))}
                        onChange={(e) => {
                          setWeek(e);
                          handleProgramChage('week', e);
                        }}
                        dropdownStyle={{ zIndex: 3500 }}
                      />
                    </Col>
                    <Col x={24} style={{ marginTop: 15 }}>
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {weeklyItemList[week]?.map((item, index) => (
                          <li
                            key={`${item?.value}-${index}`}
                            style={{
                              width: 'calc(100%/7)',
                              border:
                                day === index
                                  ? '1px solid #40A9FF'
                                  : '1px solid #E0E0E0',
                              textAlign: 'center',
                              padding: '10px 0',
                              cursor: 'pointer',
                              color: day === index && '#40A9FF',
                            }}
                            onClick={() => {
                              setDay(index);
                              handleProgramChage('weekday', item?.value);
                            }}
                          >
                            <Badge
                              count={item?.items?.length}
                              offset={screenSize >= 768 ? [15, 0] : [10, 0]}
                            >
                              {screenSize >= 768
                                ? item?.label
                                : item?.mobile_label}
                            </Badge>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col x={24} style={{ height: 500 }}>
                      {weeklyItemList[week] &&
                      weeklyItemList[week][day].items ? (
                        <List
                          grid={
                            screenSize >= 768
                              ? { gutter: 12, column: 2 }
                              : { gutter: 0, column: 1 }
                          }
                          dataSource={weeklyItemList[week][day].items}
                          renderItem={(item, index) => (
                            <List.Item key={`item-${index}-${index}`}>
                              <Card
                                title={
                                  <p>
                                    {item?.category_name}{' '}
                                    <span
                                      style={{ fontSize: 14, color: '#BDBDBD' }}
                                    >
                                      (
                                      {`${item?.start_time} ~ ${item?.end_time}`}
                                      )
                                    </span>
                                  </p>
                                }
                                hoverable
                                extra={
                                  <Btn
                                    type="text"
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRepeatSchedule('D', item);
                                    }}
                                  >
                                    <DeleteOutlined
                                      style={{ color: '#FB4E4E' }}
                                    />
                                  </Btn>
                                }
                                onClick={() => {
                                  setProgramData({
                                    ...item,
                                    start_time: item?.start_time,
                                    end_time: item?.end_time,
                                    type: item?.week ? 2 : 1,
                                  });
                                  setProgramValue(item?.content);
                                  setScheduleModal(!scheduleModal);
                                }}
                              >
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {item?.agency_name}{' '}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: '#BDBDBD',
                                    }}
                                  >
                                    ({item?.agency_type})
                                  </span>
                                </p>
                                <p>{item?.agency_address}</p>
                                <p>
                                  {item?.agency_address_detail
                                    ? item?.agency_address_detail
                                    : ''}
                                </p>
                              </Card>
                            </List.Item>
                          )}
                        />
                      ) : (
                        <Row
                          style={{
                            height: '100%',
                          }}
                        >
                          <Col x={24}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              style={{
                                height: '100%',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Content>
              )}
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 30 }}>
            <Col x={0} md={24}>
              <Button
                style={{ width: '100%', height: 56, fontSize: 20 }}
                onClick={() => setScheduleModal(!scheduleModal)}
              >
                일정추가
              </Button>
            </Col>
          </Row> */}
        </Content>
      </ModalLayout>

      {/* 일정 일괄등록 모달 */}
      <ModalLayout
        title={
          modalType === 1 ? '주별/월별 일정관리' : `${month}월 일정 일괄등록`
        }
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? '80%' : '100%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        footer={
          screenSize < 768 ? (
            <Button
              style={{ width: '100%', height: '50px', fontSize: '18px' }}
              onClick={() => handleBulkSchedule()}
            >
              등록
            </Button>
          ) : null
        }
      >
        <Content
          maxWidth={'100%'}
          padding={0}
          style={{ maxHeight: 1000, overflowY: 'scroll' }}
        >
          <Row>
            <Col x={24}>
              <Title align={'center'} size={1}>{`${year}.${month}`}</Title>
              <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 30,
                      height: 10,
                      background: '#4B55F8',
                      marginRight: 10,
                    }}
                  ></span>{' '}
                  등록된 일정
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 30,
                      height: 10,
                      background: '#BDBDBD',
                      marginRight: 10,
                    }}
                  ></span>{' '}
                  미등록 일정
                </li>
              </ul>
              <Calendar
                dateTime={new Date(year, month - 1)}
                height={800}
                isHeader={false}
                items={repetitionEvents}
                detail
              />
            </Col>
          </Row>
        </Content>
        {screenSize >= 768 && (
          <Content maxWidth={'100%'} padding={0} style={{ marginTop: '50px' }}>
            <Button
              style={{ width: '100%', height: '50px', fontSize: '18px' }}
              onClick={() => handleBulkSchedule()}
            >
              등록
            </Button>
          </Content>
        )}
      </ModalLayout>

      {/* 주별/월별 일정 추가 모달 */}
      <ModalLayout
        title={'주별/월별 일정'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={scheduleModal}
        onCancel={setScheduleModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 768 : '100%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={3600}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {/* 타입 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={24}>
                  <Radio.Group
                    value={programData?.type}
                    size="large"
                    onChange={(e) => handleProgramChage('type', e.target.value)}
                  >
                    <Radio.Button value={1}>주별 프로그램</Radio.Button>
                    <Radio.Button value={2}>월별 프로그램</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
              {/* 주 */}
              {programData?.type === 2 ? (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <Col x={5}>
                    <Title>주</Title>
                  </Col>
                  <Col x={19}>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      value={programData?.week}
                      options={[1, 2, 3, 4]?.map((item, index) => ({
                        label: `${item}주`,
                        value: index,
                      }))}
                      onChange={(e) => handleProgramChage('week', e)}
                      dropdownStyle={{ zIndex: 3700 }}
                    />
                  </Col>
                </Row>
              ) : null}
              {/* 요일 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>요일</Title>
                </Col>
                <Col x={19}>
                  <Radio.Group
                    value={programData?.weekday}
                    onChange={(e) =>
                      handleProgramChage('weekday', e.target.value)
                    }
                  >
                    {weekItems?.map((item) => (
                      <Radio value={item?.value} size="large" key={item?.value}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>
              {/* 시간 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>시간</Title>
                </Col>
                <Col
                  x={19}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="시작시간"
                    value={programData?.start_time}
                    onChange={(e) =>
                      handleProgramChage(
                        'start_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                  <p style={{ padding: '0 10px' }}>~</p>
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="종료시간"
                    value={programData?.end_time}
                    onChange={(e) =>
                      handleProgramChage(
                        'end_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                </Col>
              </Row>
              {/* 기관 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>기관</Title>
                </Col>
                <Col x={19}>
                  <Select
                    size="large"
                    options={agencyList}
                    value={programData?.agency_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 3700 }}
                    onChange={(e) => handleProgramChage('agency_id', e)}
                    placeholder="기관을 선택해주세요"
                    showSearch
                    filterOption={(e, option) =>
                      handleFilterOption(e, option?.label)
                    }
                    disabled={programData?.repetition_schedule_id}
                  />
                </Col>
              </Row>
              {/* 프로그램 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>프로그램</Title>
                </Col>
                <Col x={19}>
                  <Select
                    size="large"
                    options={treeData}
                    value={programData?.category_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 3700 }}
                    onChange={(e) => handleProgramChage('category_id', e)}
                    placeholder="프로그램을 선택해주세요"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col x={24}>
              {programData?.repetition_schedule_id ? (
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-arount',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    color="secondary"
                    layoutStyle={{ width: '45%' }}
                    style={{ width: '100%', height: 56, fontSize: 20 }}
                    onClick={() => handleRepeatSchedule('U', programData)}
                  >
                    수정
                  </Button>
                  <Button
                    layoutStyle={{ width: '45%' }}
                    style={{ width: '100%', height: 56, fontSize: 20 }}
                    onClick={() => handleRepeatSchedule('D', programData)}
                  >
                    삭제
                  </Button>
                </Content>
              ) : (
                <Button
                  style={{ width: '100%', height: 56, fontSize: 20 }}
                  onClick={() => handleRepeatSchedule('C')}
                >
                  일정추가
                </Button>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 서명 모달 */}
      <ModalLayout
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={signModal}
        onCancel={setSignModal}
        placement={screenSize >= 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 500 : '100%'}
        height={screenSize >= 768 ? '100%' : '50%'}
        centered
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={3700}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  top: 0,
                  left: 0,
                  minHeight: 150,
                  border: '1px solid #F5F5F5',
                  padding: 10,
                }}
              >
                {createObjectURL || myData?.signature ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: 5,
                      left: 5,
                      color: '#FF6262',
                      fontSize: 15,
                      padding: 5,
                      border: '1px solid #FF6262',
                      // background: '#FF6262',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 3,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDeleteSignFile()}
                  >
                    <DeleteOutlined />
                  </div>
                ) : null}
                {createObjectURL || myData?.signature ? (
                  <img
                    src={
                      myData?.signature
                        ? `${STORE_URL}/${
                            loginWho === 'agency' ? 'agency' : 'users'
                          }/${uid}/${myData?.signature}`
                        : createObjectURL
                    }
                    alt="서명"
                    style={{ width: '100%', height: '100%' }}
                  />
                ) : (
                  <Title>서명파일을 등록해주세요!</Title>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col x={24}>
              {signFile ? (
                <Button
                  style={{ width: '100%', marginTop: 15, height: 54 }}
                  onClick={() => handleUploadFile()}
                >
                  등록하기
                </Button>
              ) : myData?.signature ? null : (
                <FileUploadButton onChange={handleSignImage} accept={'image/*'}>
                  + 서명 파일 등록
                </FileUploadButton>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 활동일지 리스트 모달 */}
      <ModalLayout
        title={'강의 일지'}
        type={'drawer'}
        open={journalModal}
        onCancel={setJournalModal}
        placement={screenSize >= 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 500 : '100%'}
        height={'80%'}
        closable
        bodyStyle={{ padding: '15px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {journalData?.map((item) => (
                <div
                  key={item?.key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #EEEEEE',
                    margin: '5px 0',
                    padding: '20px 10px',
                  }}
                >
                  <div>
                    <Title>{item?.data[0]?.category_name}</Title>
                    <p style={{ fontSize: 18 }}>{item?.data[0]?.name}</p>
                  </div>
                  <Button
                    layoutStyle={{ margin: 0 }}
                    onClick={() => handlePopup(item?.data)}
                  >
                    보기
                  </Button>
                </div>
              ))}
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

MySchedulePresenter.defualtProps = {};

export default MySchedulePresenter;
