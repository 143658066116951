import React, { useState, useEffect } from 'react';
import ProductRegisterPresenter from './ProductRegisterPresenter';
import { useSearchParams } from 'react-router-dom';
import { CategoryApi, FileApi, ProductApi, ProgramApi } from 'api';
import { decrypt } from 'utils/Crypto';

const ProductRegisterContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('product');

  /* ===== State ===== */
  const [newProduct, setNewProduct] = useState({});

  const [type, setType] = useState('create');
  const { data: product, refetch } = ProductApi.GetProduct(
    parseInt(decrypt(productId)),
    null,
    {
      enabled: !!productId,
    }
  );
  const [categories, setCategories] = useState([]);
  const [productFile, setProductFile] = useState(null);
  const pFiles = FileApi.GetProductFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const programs = ProgramApi.GetPrograms(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const programCategories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  /* ===== Hooks ===== */
  useEffect(() => {
    if (productId) refetch();
  }, [productId, refetch]);

  useEffect(() => {
    if (productId && product && product.status === 200) {
      const { productCategories } = data;
      const p = {
        ...product?.data,
        ...(productCategories &&
          productCategories?.filter(
            (item) =>
              item?.product_category_id === product?.data?.product_category_id
          )[0]),
      };

      setNewProduct(p);
      setType('update');

      setCategories(productCategories);

      const f = pFiles?.filter(
        (item) => item?.product_id === parseInt(decrypt(productId))
      );
      if (f?.length) {
        const fData = f.pop();
        setProductFile(fData);
      }
    } else {
      setNewProduct({});
      setProductFile(null);
      setType('create');

      const { productCategories } = data;
      setCategories(productCategories);
    }
  }, [product, data, pFiles, productId]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <ProductRegisterPresenter
      data={newProduct}
      type={type}
      categories={categories}
      productFile={productFile}
      programs={programs}
      programCategories={programCategories}
    />
  );
};

ProductRegisterContainer.defaultProps = {};

export default ProductRegisterContainer;
