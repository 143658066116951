import React from 'react';
import ProductCartPresenter from './ProductCartPresenter';
import { getCookie } from 'utils';
import {
  ProductApi,
  UserApi,
  DeliveryAddressApi,
  AgencyApi,
  FileApi,
  ProductCartApi,
  PurchaseApi,
} from 'api';

const ProductCartContainer = ({ loginWho }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const idx = parseInt(getCookie('USER'));
  const user =
    loginWho === 'agency'
      ? AgencyApi.GetAgency(idx)?.data?.data
      : UserApi.GetUser(idx)?.data?.data;
  const recommend = ProductApi.GetRecommendProducts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const products = ProductApi.GetProducts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productFiles = FileApi.GetProductFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productCategories = ProductCartApi.GetProductCarts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const productCarts = ProductCartApi.GetProductCarts(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const purchases = PurchaseApi.GetPurchases(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const address = DeliveryAddressApi.GetDeliveryAddresses(
    loginWho === 'agency'
      ? {
          agency_id: idx,
        }
      : {
          user_id: idx,
        }
  )?.data?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <ProductCartPresenter
      user={user}
      products={products}
      productFiles={productFiles}
      productCarts={productCarts}
      purchases={purchases}
      productCategories={productCategories}
      idx={idx}
      recommend={recommend}
      address={address}
    />
  );
};

ProductCartContainer.defaultProps = {};

export default ProductCartContainer;
