import React from 'react';
import LectureCartPresenter from './LectureCartPresenter';
import { getCookie } from 'utils';
import {
  LectureApi,
  AgencyApi,
  UserApi,
  FileApi,
  InterestListApi,
  CategoryApi,
  LectureOfUserApi,
} from 'api';

const LectureCartContainer = ({ loginWho }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const idx = parseInt(getCookie('USER'));
  const user =
    loginWho === 'agency'
      ? AgencyApi.GetAgency(idx)?.data?.data
      : UserApi.GetUser(idx)?.data?.data;
  const lectures = LectureApi.GetLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const recommend = LectureApi.GetRecommendLectures(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureFiles = FileApi.GetLectureFiles(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const users = UserApi.GetUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const interestLists = InterestListApi.GetInterestLists(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const categories = CategoryApi.GetCategories(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;
  const lectureOfUsers = LectureOfUserApi.GetLectureOfUsers(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <LectureCartPresenter
      user={user}
      lectures={lectures}
      lectureFiles={lectureFiles}
      users={users}
      interestLists={interestLists}
      lectureOfUsers={lectureOfUsers}
      categories={categories}
      idx={idx}
      recommend={recommend}
    />
  );
};

LectureCartContainer.defaultProps = {};

export default LectureCartContainer;
