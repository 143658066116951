import React, { useState, useEffect, useMemo } from 'react';
import { Content, Row, Col, Sidebar } from 'components';
import { TreeSelect } from 'antd';
// import Dashboard from './Dashboard';
import Myinfo from './MyInfo';
import MySchedule from './MySchedule';
import Profile from './Profile';
import MyLearning from './MyLearning';
import MyTest from './MyTest';
import MyPosts from './MyPosts';
import LectureCart from './LectureCart';
import LectureFavorites from './LectureFavorites';
import LectureOrders from './LectureOrders';
import LectureList from './LectureList';
import LectureJournal from './LectureJournal';
import ProductCart from './ProductCart';
import Purchase from './Purchase';
import EventList from './EventList';
import MyCalculate from './MyCalculate';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { getCookie, deleteCookie } from 'utils';
import { encrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';

import { ProgramApi, ScheduleApi } from 'api';

const MypageRoutes = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();

  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  const programs = ProgramApi.GetPrograms(null, {
    select: (res) => {
      const { status, message, data } = res;
      if (status !== 200) {
        console.error(message);
        return [];
      }

      return data;
    },
  })?.data;

  const isRegisterProgram = programs?.some(
    (program) => program?.user_id === parseInt(uid)
  );

  /* ===== STATE ===== */

  // 접속자 확인 (수강생, 강사, 기관)
  const loginWho = useRecoilValue(loginUser);
  const [sideMenu, setSideMenu] = useState([]);
  // SideMenu 아이템
  const items = useMemo(() => {
    const list = {
      user: [
        {
          title: '',
          menus: [
            {
              label: '내 정보',
              path: '/mypage/my_info',
            },
            {
              label: '참여 이벤트',
              path: '/mypage/events',
            },
          ],
        },
        {
          title: '학습관리',
          menus: [
            {
              label: '나의 학습',
              path: '/mypage/my_learning',
            },
            {
              label: '온라인 테스트',
              path: '/mypage/my_test',
            },
            {
              label: '내가 작성한 리뷰',
              path: '/mypage/my_posts',
            },
          ],
        },
        {
          title: '수강관리',
          menus: [
            {
              label: '수강바구니',
              path: '/mypage/lecture/cart',
            },
            {
              label: '찜목록',
              path: '/mypage/lecture/favorites',
            },
            {
              label: '구매내역',
              path: '/mypage/lecture/orders',
            },
          ],
        },
        {
          title: '상품',
          menus: [
            {
              label: '장바구니',
              path: '/mypage/product/cart',
            },
            {
              label: '구매내역',
              path: '/mypage/product/purchase',
            },
          ],
        },
      ],
      teacher: [
        {
          title: '',
          menus: [
            {
              label: '내 정보',
              path: '/mypage/my_info',
            },
            {
              label: '참여 이벤트',
              path: '/mypage/events',
            },
            {
              label: '정산내역',
              path: '/mypage/my_calculate',
            },
          ],
        },
        {
          title: '강사 메뉴',
          menus: [
            {
              label: '프로필',
              path: '/mypage/profile',
            },
            {
              label: '프로필 관리',
              path: isRegisterProgram
                ? `/profile?profile=${encodeURIComponent(
                    encrypt(getCookie('USER'))
                  )}`
                : '/profile',
            },
            {
              label: '등록 강의',
              path: '/mypage/my_lecture',
            },
            {
              label: '일정 관리',
              path: '/mypage/my_schedule',
            },
          ],
        },
        {
          title: '학습관리',
          menus: [
            {
              label: '나의 학습',
              path: '/mypage/my_learning',
            },
            {
              label: '온라인 테스트',
              path: '/mypage/my_test',
            },
            {
              label: '내가 작성한 리뷰',
              path: '/mypage/my_posts',
            },
          ],
        },
        {
          title: '수강관리',
          menus: [
            {
              label: '수강바구니',
              path: '/mypage/lecture/cart',
            },
            {
              label: '찜목록',
              path: '/mypage/lecture/favorites',
            },
            {
              label: '구매내역',
              path: '/mypage/lecture/orders',
            },
          ],
        },
        {
          title: '상품',
          menus: [
            {
              label: '장바구니',
              path: '/mypage/product/cart',
            },
            {
              label: '구매내역',
              path: '/mypage/product/purchase',
            },
          ],
        },
      ],
      agency: [
        {
          title: '',
          menus: [
            {
              label: '내 정보',
              path: '/mypage/my_info',
            },
            {
              label: '일정 관리',
              path: '/mypage/my_schedule',
            },
            {
              label: '청구내역',
              path: '/mypage/my_calculate',
            },
          ],
        },
        {
          title: '상품',
          menus: [
            {
              label: '장바구니',
              path: '/mypage/product/cart',
            },
            {
              label: '구매내역',
              path: '/mypage/product/purchase',
            },
          ],
        },
      ],
    };

    switch (loginWho) {
      case 'user':
      case 'User':
      case 'USER':
        return list.user;
      case 'teacher':
      case 'Teacher':
      case 'TEACHER':
        return list.teacher;
      case 'agency':
      case 'Agency':
      case 'AGENCY':
        return list.agency;
      default:
        break;
    }
  }, [loginWho, isRegisterProgram]);

  const [scheduleCount, setScheduleCount] = useState(0);

  const { data: schedules } = ScheduleApi.GetSchedules(
    loginWho === 'agency'
      ? {
          agency_id: parseInt(uid),
          date: `${year}-${month}`,
        }
      : {
          user_id: parseInt(uid),
          date: `${year}-${month}`,
        },
    {
      enabled: uid && year && month ? true : false,
    }
  );
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [treeValue, setTreeValue] = useState('');

  /* ===== FUNCTION ===== */

  /* ===== HOOKS ===== */
  // 마이페이지 사이드 메뉴 렌더링
  useEffect(() => {
    setSideMenu(items);
  }, [items]);

  /**
   * 비로그인 라우팅 처리
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }
  }, [token, navigate, uid]);

  /**
   * 요청받은 스케줄 카운트
   * --
   */
  useEffect(() => {
    if (token && (loginWho === 'teacher' || loginWho === 'agency')) {
      const newSchedules = schedules?.data?.filter(
        (item) =>
          item?.schedule_type === 'R' &&
          (loginWho === 'teacher'
            ? item?.user_confirm === 'C'
            : item?.agency_confirm === 'C')
      );
      setScheduleCount(newSchedules?.length);
    }
  }, [token, loginWho, schedules]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0} style={{ margin: '48px 0 84px' }}>
      <Content padding={15} maxWidth={1200}>
        <Row>
          {/* 사이드 메뉴 */}
          <Col x={24} md={4}>
            {screenSize >= 768 ? (
              <Sidebar items={sideMenu} scheduleCount={scheduleCount} />
            ) : (
              <div style={{ padding: '0 12px' }}>
                <TreeSelect
                  style={{
                    width: '100%',
                    marginBottom: '30px',
                  }}
                  value={treeValue}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  treeData={sideMenu?.map((item) => ({
                    label: item?.title === '' ? '내정보' : item?.title,
                    value: item?.title,
                    selectable: false,
                    children: item?.menus?.map((subitem) => ({
                      label: subitem?.label,
                      value: subitem?.path,
                    })),
                  }))}
                  placeholder="Please select"
                  treeDefaultExpandAll
                  onChange={(e) => {
                    setTreeValue(e);
                    navigate(e);
                  }}
                  size="large"
                />
              </div>
            )}
          </Col>
          <Col x={24} md={20}>
            <Routes>
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route path="my_info" element={<Myinfo />} />
              <Route
                path="events"
                element={<EventList screenSize={screenSize} />}
              />
              <Route
                path="my_calculate"
                element={<MyCalculate screenSize={screenSize} />}
              />
              <Route path="my_schedule" element={<MySchedule />} />
              <Route path="profile" element={<Profile />} />
              <Route path="my_learning" element={<MyLearning />} />
              <Route
                path="my_test"
                element={<MyTest screenSize={screenSize} />}
              />
              <Route
                path="my_posts"
                element={<MyPosts screenSize={screenSize} />}
              />
              <Route
                path="my_lecture"
                element={<LectureList screenSize={screenSize} />}
              />
              <Route path="my_lecture_journal" element={<LectureJournal />} />
              <Route path="/lecture/*">
                <Route
                  path="cart"
                  element={<LectureCart loginWho={loginWho} />}
                />
                <Route
                  path="favorites"
                  element={<LectureFavorites loginWho={loginWho} />}
                />
                <Route path="orders" element={<LectureOrders />} />
              </Route>
              <Route path="/product/*">
                <Route
                  path="cart"
                  element={<ProductCart loginWho={loginWho} />}
                />
                <Route path="purchase" element={<Purchase />} />
              </Route>
            </Routes>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default MypageRoutes;
